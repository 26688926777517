import standardizeTexts from "../../common/standardize_texts";
import ReactGA from "react-ga4";
import ExamplesComponent from "./examples.component";
import Suggestions from "./suggestions.component";
import QuestionType from "../../types/question.type";
import React, {ChangeEvent, useEffect, useState, useRef, useCallback} from "react";
import Utils from "../../common/utils";
import UiConfig from "../../common/ui-config";
import useWindowDimensions from "../../common/useWindowDimentions";

const UI_STRINGS = require('../../common/ui-strings.json');

interface Props {
    type: 'connections' | 'regression' | 'correlation' | 'mainSearch';
    setFunction: (str: string) => void;
    org_id: string;
    excludeExamples: string[];
    forbiddenInputStrings: string[];
    isLoadingResults: boolean;
    questionsForExamples: () => QuestionType[];
    isLoadingExamples: boolean;
    custom_text: string;
    lockRequests: boolean;
    randomizeExamplesClicked?: () => void;
}

const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout;
    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const InputBlockComponent: React.FC<Props> = (props) => {
    const {height, width} = useWindowDimensions();
    const {
        type,
        setFunction,
        org_id,
        excludeExamples,
        forbiddenInputStrings,
        isLoadingExamples,
        questionsForExamples,
        lockRequests,
        randomizeExamplesClicked,
        isLoadingResults
    } = {...props};
    let button_type = type;
    width < 992 ? button_type += '_short' : button_type += '_long';
    let pageClass;
    type !== 'mainSearch' ? pageClass = 'questionTab' : pageClass = 'searchPage';

    const [inputHandle, setInputHandle] = useState<string>("");
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [quietSuggestions, setQuietSuggestions] = useState<boolean>(true);
    const [exampleClicked, setExampleClicked] = useState(false);
    const [alertMsg, setAlertMsg] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const debouncedSetInputHandle = useCallback(debounce((value: string) => {
        setInputHandle(value);
        if (alertMsg) {
            setAlertMsg(null);
        }
    }, 300), [alertMsg]);

    useEffect(() => {
        if (!lockRequests) {
            setAlertMsg('');
        }
        if (lockRequests && !isSubmitting) {
            setAlertMsg(UI_STRINGS.notifications.needSignUp)
        }
    }, [lockRequests])

    useEffect(() => {
        console.log("DEBUG: LOCK REQUESTS: ", lockRequests)
    }, [lockRequests])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedSetInputHandle(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') {
            setExampleClicked(false);
            setAlertMsg('');
        }
        if (event.key === 'Enter' && !isSubmitting && !lockRequests) {
            const inputValue = (event.currentTarget as HTMLInputElement).value;
            setInputHandle(inputValue);
            setQuietSuggestions(true);
            acceptInput(inputValue);
            setExampleClicked(true);
        }
        if (event.key === 'Enter' && lockRequests) {
            setAlertMsg(UI_STRINGS.notifications.needSignUp)
        }
    };

    const handleSuggestionOrExampleClick = (e: any) => {
        if (lockRequests) return;
        const str = Utils.getTextFromSuggestion(e);
        const standardizedText = standardizeTexts(str);
        setInputHandle(standardizedText);
        if (inputRef.current) {
            inputRef.current.value = standardizedText;
        }
        setExampleClicked(true);
        setQuietSuggestions(true);
        acceptInput(standardizedText);
    };


// Handle button click, now updated with proper dependency and logs
    const handleButtonClick = useCallback(() => {
        console.log("Button clicked");
        console.log("Current inputHandle value:", inputHandle);
        if (inputHandle) {
            setQuietSuggestions(true);
            acceptInput(inputHandle);
        } else {
            console.log("inputHandle is empty or not set");
        }
    }, [inputHandle]);

    const sendEventGA = () => {
        ReactGA.event({
            category: type === 'connections' ? "Connect two statements" : `Predict ${type}`,
            action: `${type.charAt(0).toUpperCase() + type.slice(1)}: User typed text and clicked submit`,
            label: inputHandle,
        });
    };

    const acceptInput = (currentInput: string) => {
        if (forbiddenInputStrings.includes(currentInput)) {
            setAlertMsg(UI_STRINGS.notifications.inputAlreadyInResults);
            setQuietSuggestions(true);
            return;
        }

        setIsSubmitting(true);
        const standardizedText = standardizeTexts(currentInput);
        setFunction(standardizedText);
        sendEventGA();

        setTimeout(() => setIsSubmitting(false), 100);
    };

    useEffect(() => {
        if (inputHandle.trim().length === 0) {
            setQuietSuggestions(true);
        } else if (inputHandle && !isLoadingResults && !exampleClicked) {
            if (inputHandle.trim().replace(/\s+/g, ' ').length >= 2) {
                const debouncer = setTimeout(() => {
                    setQuietSuggestions(false);
                }, UiConfig.suggestionsTimeout);
                return () => {
                    clearTimeout(debouncer);
                };
            } else {
                setQuietSuggestions(true);
            }
        } else {
            setQuietSuggestions(true);
        }
    }, [inputHandle]);


    useEffect(() => {
    }, [quietSuggestions]);

    const filteredQuestionsForExamples = questionsForExamples().filter(example =>
        !excludeExamples.includes(example.canonical_text)
    );

    return (
        <div>
            <div className={"inputWrapper d-flex align-items-start " + pageClass}>
                {type !== 'mainSearch' && (
                    <div className="inputGroupLabelOneLine">Question&nbsp;<span
                        className="d-none d-lg-inline">text</span></div>
                )}
                <div className="flex-grow-1">
                    <input
                        ref={inputRef}
                        type="text"
                        id={`${type}Input`}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown} // Changed to onKeyDown
                        placeholder={UI_STRINGS.mainSearch.inputPlaceholder}
                        minLength={2} required
                    />
                    <Suggestions
                        stringHandle={inputHandle}
                        excludeSuggestions={forbiddenInputStrings}
                        isLoadingSearchResults={isLoadingResults}
                        isLocked={lockRequests}
                        org_id={org_id}
                        onSuggestionClick={handleSuggestionOrExampleClick}
                        quietSuggestions={quietSuggestions}
                        excludeResults={excludeExamples}
                        source={'Predict ' + type.charAt(0).toUpperCase() + type.slice(1)}
                    />
                </div>
                <button
                    className={"btn btn-primary"}
                    type="button"
                    onClick={handleButtonClick}
                    disabled={lockRequests}
                >{UI_STRINGS.labels.buttons[button_type]}
                </button>
            </div>
            <ExamplesComponent
                questionsForExamples={filteredQuestionsForExamples}
                isLoadingExamples={isLoadingExamples}
                lockRequests={lockRequests}
                onExampleClick={handleSuggestionOrExampleClick}
                source={'Examples for ' + type}
                randomizeExamplesClicked={randomizeExamplesClicked}
            />
            {(!isLoadingResults && alertMsg) && (
                <div className="alert alert-light mt-0" role="alert">
                    {alertMsg}
                </div>
            )}
        </div>
    );
};

export default InputBlockComponent;