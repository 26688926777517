export const layouts: Record<string, any> = {
    random: {
        name: 'random',
        fit: true,
        padding: 25, // Moderately reduced padding
        boundingBox: undefined,
        animate: true,
        animationDuration: 500,
        animationEasing: 'ease-in-out',
        ready: undefined,
        stop: undefined,
        nodeDimensionsIncludeLabels: true,
        avoidOverlap: true,
        spacingFactor: 1.2, // Moderately reduced spacing factor
    },
    preset: {
        name: 'preset',
        padding: 10,
    },
    grid: {
        name: "grid",
        animate: true,
        animationDuration: 500,
        animationEasing: 'ease-in-out',
        spacingFactor: 1.2, // Moderately reduced spacing factor
        fit: true,
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: true,
    },
    circle: {
        name: "circle",
        nodeDimensionsIncludeLabels: true,
        animate: true,
        animationDuration: 500,
        animationEasing: 'ease-in-out',
        fit: true,
        padding: 25, // Moderately reduced padding
        boundingBox: undefined,
        avoidOverlap: true,
        spacingFactor: 0.8, // Moderately reduced spacing factor
    },
    klay: {
        name: "klay",
        animate: false,
        padding: 10, // Moderately reduced padding
        nodeDimensionsIncludeLabels: true,
        avoidOverlap: true,
        klay: {
            spacing: 40, // Moderately reduced KLay spacing
            mergeEdges: false,
        },
    },
    fcose: {
        name: "fcose",
        nodeDimensionsIncludeLabels: true,
        animate: true,
        animationDuration: 500,
        animationEasing: 'ease-in-out',
        padding: 15, // Moderately reduced padding
        avoidOverlap: true,
    },
    'cose-bilkent': {
        name: 'cose-bilkent',
        animate: false,
        nodeDimensionsIncludeLabels: true,
        padding: 10,
        nodeRepulsion: 5000, // Moderately reduced node repulsion
        idealEdgeLength: 80, // Moderately reduced edge length
        edgeElasticity: 0.45,
        nestingFactor: 0.1,
        gravity: 0.25,
        numIter: 2500,
        tile: true,
        fit: true,
        coolingFactor: 0.99,
        nodeSeparation: 800, // Moderately reduced node separation
        initialTemp: 1000,
        nodeOverlap: 10
    },
    cola: {
        name: "cola",
        nodeDimensionsIncludeLabels: true,
        animate: false,
        nodeSpacing: 15, // Moderately reduced node spacing
        edgeLengthVal: 30, // Moderately reduced edge length
        avoidOverlap: true,
    },
    dagre: {
        name: "dagre",
        nodeDimensionsIncludeLabels: true,
        animate: true,
        animationDuration: 500,
        animationEasing: 'ease-in-out',
        spacingFactor: 1.2, // Moderately reduced spacing factor
        avoidOverlap: true,
    },
    breadthfirst: {
        name: 'breadthfirst',
        fit: true,
        directed: true,
        padding: 15, // Moderately reduced padding
        circle: false,
        grid: false,
        animate: true,
        animationDuration: 500,
        animationEasing: 'ease-in-out',
        spacingFactor: 2.0, // Moderately reduced spacing factor
        boundingBox: undefined,
        avoidOverlap: true,
        roots: ['Statement1', 'Statement2'],
        maximal: true
    }
};
// Add enhanced ELK layouts:
["box", "disco", "force", "layered", "mrtree", "stress"].forEach(
    (elkAlgo) => {
        layouts[`elk_${elkAlgo}`] = {
            name: 'elk',
            animate: false,
            nodeDimensionsIncludeLabels: true,
            fit: true,
            padding: 25, // Moderately reduced padding
            boundingBox: undefined,
            avoidOverlap: true,
            spacingFactor: 1.2, // Moderately reduced spacing factor
            elk: {
                algorithm: elkAlgo,
                'elk.direction': 'DOWN',
                'elk.spacing.nodeNode': 40, // Moderately reduced node-to-node spacing
                'elk.spacing.edgeNode': 25, // Moderately reduced edge-to-node spacing
            },
        };
    }
);