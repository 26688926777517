import React from 'react';

export const Footer = () => {
    return (
        <footer className="pe-4" style={{position: 'absolute', bottom: '1rem', right: 0, display: 'flex',}}>

            <a className="text-muted pe-4" href='/terms'>Terms&nbsp;of&nbsp;use</a>
            <a className="text-muted" href='/privacy-policy'>Privacy&nbsp;policy</a>

        </footer>
    )
}