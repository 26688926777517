import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import React, {useEffect} from "react";

const NotFoundPage = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.event({
            category: "404", action: "Page not found event",
            label: location,
        })

    }, [location]);
    return (
        <div>
            <h2>404 - Page Not Found</h2>
            <p>The page you are looking for doesn't exist.</p>
            <p>Path: {location.pathname}</p>
        </div>
    );
}
export default NotFoundPage