import emailClient from "../common/email-client";

const sendFeedbackNotification = async (senderEmail: string, senderMessage: string) => {
    try {
        const body: {} = {
            "feedback_sender": senderEmail,
            "feedback_message": senderMessage,
        }
        const response = await emailClient.post<any>("/sendfeedback", body);
        return response.data
    } catch (error) {
        console.error(error);
    }
}
const EmailService = {sendFeedbackNotification,}
export default EmailService;