import React, {useEffect, useState} from "react";
import QuestionType from "../../types/question.type";
import {LoadingSimple, OnlySurprisesSwitch, Surprise} from "../UIElements";
import {useAuthenticator} from "@aws-amplify/ui-react";
import ResultRow from "./resultRow";
import ReactGA from "react-ga4";

const UI_STRINGS = require('../../common/ui-strings.json')

type Props = {
    custom_text: string | null,
    isLoadingMostCorrelated: boolean,
    isLoadingStudyCorrelations: boolean,
    mostCorrelatedResult: QuestionType[],
    questionSelected: (q: QuestionType) => void,
    actualCorrelations: any[] | null,
    debugMode: boolean,
    mainQLink: QuestionType,
    accessLevel?: number | undefined,
    requireAuth: () => void,
    handleShowStudyDetails: (index: number, corrItem: any) => void,
};

const MostCorrelated = (props: Props) => {
    const custom_text = props.custom_text;
    const handleShowStudyDetails = props.handleShowStudyDetails;
    const isLoadingMostCorrelated = props.isLoadingMostCorrelated;

    const questionSelected = props.questionSelected;
    const requireAuth = props.requireAuth;
    const isLoadingStudyCorrelations = props.isLoadingStudyCorrelations;
    const debugMode = props.debugMode;
    const mainQLink = props.mainQLink;
    const [showOnlySurprising, setShowOnlySurprising] = useState<boolean>(false)
    const [switchState, setSwitchState] = useState(false);
    const [mostCorrelatedResult, setMostCorrelatedResult] = useState<QuestionType[]>(props.mostCorrelatedResult.filter((r) => r.canonical_text !== mainQLink.canonical_text));
    const [actualCorrelations, setActualCorrelations] = useState<any[] | null>(props.actualCorrelations);


    function toggleShowOnlySurprising(e: any) {
        const checked = e.target.checked;
        setShowOnlySurprising(checked);
        setSwitchState(checked);
        ReactGA.event({
            category: "Most Correlated Component",
            action: "Toggled Show only surprises",
            value: checked
        })
    }

    const {route} = useAuthenticator(context => [context.route]);

    useEffect(() => {
        let resultsToDisplay;
        let actualCorrelationsToDisplay: any[] | null = null;
        if (showOnlySurprising && props.mostCorrelatedResult.filter((r) => r.canonical_text !== mainQLink.canonical_text)) {
            resultsToDisplay = props.mostCorrelatedResult.filter((r) => r.score?.is_surprising == true && r.canonical_text !== mainQLink.canonical_text)
            let indexesToDisplay = props.mostCorrelatedResult.filter((r) => r.canonical_text !== mainQLink.canonical_text).map((r, index) => {
                if (r.score?.is_surprising) return index
            })
            if (props.actualCorrelations !== null) {
                actualCorrelationsToDisplay = [];
                for (let i of indexesToDisplay) {
                    if (i !== undefined) {
                        let el = props.actualCorrelations[i];
                        actualCorrelationsToDisplay.push(el);
                    }

                }
            }

        } else {
            resultsToDisplay = props.mostCorrelatedResult.filter((r) => r.canonical_text !== mainQLink.canonical_text);
            actualCorrelationsToDisplay = props.actualCorrelations;
        }

        setMostCorrelatedResult(resultsToDisplay);
        setActualCorrelations(actualCorrelationsToDisplay);
    }, [showOnlySurprising, isLoadingStudyCorrelations]);

    return (
        <div className="mt-4 mt-lg-1 mt-xxl-1 ">
            {!isLoadingMostCorrelated && mostCorrelatedResult && (
                <div className="mostCorrWrapper">

                    <table className="table-responsive w-100 mostCorrWrapper ">
                        <thead>
                        <tr className="">
                            <td></td>
                            <td colSpan={2} className={"lead  text-start  table-heading"}>
                                <div className="float-start pe-3">Predicted<br/>correlations</div>
                                <div className='mt-auto ps-2'>
                                    <br/>
                                    <OnlySurprisesSwitch mostCorrelatedResult={mostCorrelatedResult}
                                                         toggleShowOnlySurprising={toggleShowOnlySurprising}
                                                         switchState={switchState}/>
                                </div>


                            </td>
                            <td colSpan={2} className='position-relative'>
                                <div
                                    className="lead  text-start table-heading ps-3 pe-3" style={{
                                    position: 'absolute',
                                    right: '3.7rem', top: 0,
                                }}>{!custom_text ? "Study" : ''}<br/>{!custom_text ? "correlations" : ''}
                                    <span className='very_small_font'>
                                                        {(mainQLink && !custom_text && !isLoadingStudyCorrelations && actualCorrelations && actualCorrelations.length == 0) ? 'nothing found' : ''}
                                                    </span>
                                </div>
                            </td>


                        </tr>
                        </thead>
                        <tbody>

                        {mostCorrelatedResult.filter((q: QuestionType) => q.canonical_text !== mainQLink.canonical_text).map((q: QuestionType, index: number) => (
                            <ResultRow q={q} index={index} questionSelected={questionSelected}
                                       actualCorrelations={actualCorrelations}
                                       isLoadingStudyCorrelations={isLoadingStudyCorrelations} debugMode={debugMode}
                                       key={'row_' + index} accessLevel={props.accessLevel} requireAuth={requireAuth}
                                       statement_searched={mainQLink.canonical_text}
                                       handleShowStudyDetails={handleShowStudyDetails}/>
                        ))}

                        </tbody>
                    </table>

                </div>
            )}
        </div>


    );
}
export default MostCorrelated;
