import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Button, Dropdown, Form} from 'react-bootstrap';
import VerboseDiagram from '../cyDiagram/VerboseDiagram';
import NetworkDiagramData from '../../types/networkDiagram.type';
import {getNetworkDiagramElements} from '../cyDiagram/dataTransforms';

type Props = {
    data: NetworkDiagramData;
};

const NetworkDiagram = (props: Props) => {
    const style = {
        width: '100%',
        height: '100%',
        border: 'none',
        marginTop: '1.5rem',
        padding: '1rem',
        borderTop: '1px lightgray solid',
    };
    const {data} = {...props};
    const layoutKeys = ['grid', 'circle', 'klay', 'cola', 'dagre', 'elk_box', 'elk_disco', 'elk_force', 'elk_layered', 'elk_mrtree'];
    const seenEdges = new Set<string>();
    const [showCorrelation, setShowCorrelation] = useState<boolean>(false);
    const [edgeWidthCoefficient, setEdgeWidthCoefficient] = useState<number>(10);
    const [nodesLimit, updateNodesLimit] = useState<number>(6);
    const [elements, setElements] = useState<{}[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [layoutName, setLayoutName] = useState<string>('circle');
    const [loading, setLoading] = useState<boolean>(true);

    useLayoutEffect(() => {
        const updateSize = () => {
            setPageHeight(window.innerHeight);
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        const newElements = getNetworkDiagramElements(data, nodesLimit, seenEdges);
        setElements(newElements);
    }, [nodesLimit, data]);

    useEffect(() => {
        const slider = document.getElementById('networkEdgeWidthSlider');
        if (!slider) return;
        const handleInput = (event: any) => setEdgeWidthCoefficient(event.target.value);
        slider.addEventListener('input', handleInput);
        return () => slider.removeEventListener('input', handleInput);
    }, []);

    const [pageHeight, setPageHeight] = useState<number>(0);
    const minHeight = 600;
    const sizeToUse = Math.max(minHeight, pageHeight);

    useEffect(() => {
        if (elements && sizeToUse !== 0 && pageHeight !== 0) {
            setLoading(false);
        }
    }, [elements, sizeToUse, pageHeight, edgeWidthCoefficient]);

    return (
        <div className="cyContainer d-flex justify-content-between flex-column" style={{height: sizeToUse + 'px'}}>
            <div className="cyControls d-flex flex-column flex-sm-row" style={{maxWidth: '80%'}}>
                <Dropdown className="dropdown pe-4" onSelect={(e) => {
                    if (e !== null) {
                        setLayoutName(e);
                    }
                }}>
                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                        Select Layout
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {layoutKeys.map((l) => (
                            <Dropdown.Item key={l} eventKey={l}>
                                {l}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                <div className="pe-4">
                    Edge width
                    <input type="range" className="form-range" min="0" max="25" id="networkEdgeWidthSlider"
                           style={{border: 'none'}} defaultValue="10"
                    />
                </div>
                <div style={{lineHeight: 1, paddingRight: '1rem', paddingTop: '5px'}}
                     className="d-none d-lg-flex">
                    <Form.Check
                        type="checkbox"
                        id="correlationCheckbox"
                        label={<span>Show<br/>correlations</span>}
                        checked={showCorrelation}
                        onChange={(e) => setShowCorrelation(e.target.checked)}
                    />
                </div>
                <Button variant="outline-primary" className="mb-3 me-2" style={{lineHeight: 1}}
                        onClick={() => {
                            seenEdges.clear();
                            if (nodesLimit + 1 <= data.length) {
                                updateNodesLimit(nodesLimit + 1);
                                setReload(true); // Trigger reload for layout application
                            }
                        }}
                        disabled={nodesLimit >= data.length}
                >
                    Add node
                </Button>
                <Button variant="outline-primary" className="mb-3 me-2" style={{lineHeight: 1}}
                        onClick={() => {
                            nodesLimit === 6 ? setReload(!reload) : updateNodesLimit(6);
                        }}
                >Reset</Button>
            </div>
            <VerboseDiagram
                elements={elements}
                layoutName={layoutName}
                style={style}
                showCorrelation={showCorrelation}
                edgeWidthCoefficient={edgeWidthCoefficient}
                nodesLimit={nodesLimit}
                latestNodeId={data[nodesLimit - 1]?.base_question_text}
                reload={reload}
                loading={loading}
                onLayoutApplied={() => setReload(false)} // Reset reload flag after layout application
            />
        </div>
    );
};

export default NetworkDiagram;