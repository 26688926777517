import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import QuestionDataService from "../../services/question.service";
import QuestionType from "../../types/question.type";
import Utils from "../../common/utils";
import standardizeTexts from "../../common/standardize_texts";
import UiConfig from "../../common/ui-config";
import {re} from "mathjs";
import ReactGA from "react-ga4";

type Props = {
    stringHandle: string,
    excludeSuggestions: string[],
    isLoadingSearchResults: boolean,
    isLocked: boolean,
    org_id: string,
    handleErrors?: (msg: string, err: any) => void,
    onSuggestionClick: (e: any) => void,
    quietSuggestions: boolean,
    excludeResults: string[],
    source?: string,

};

interface ISuggestions {
    queryStr: string,
    suggestions: string[]
}

const Suggestions = (props: Props) => {

    const {
        stringHandle,
        excludeSuggestions,
        quietSuggestions,
        isLoadingSearchResults,
        org_id,
        handleErrors,
        onSuggestionClick,
        isLocked,
        excludeResults
    } = {...props}

    const [suggestions, setSuggestions] = useState<ISuggestions | null>(null);
    const {isFetching: isLoadingSuggestions, refetch: getSearchSuggestions} = useQuery(
        "query-search-suggestions",
        async () => {
            if (stringHandle !== null && !isLoadingSearchResults && !quietSuggestions) {
                return await QuestionDataService.getSearchSuggestions(org_id, stringHandle.trim().replace(/\s+/g, ' '));
            } else return null;
        }, {
            enabled: false,
            retry: 2,
            onSuccess: (res) => {
                displaySearchSuggestions(res);

            },
            onError: (err: any) => {
                if (handleErrors)
                    handleErrors('glitch', err);
            }

        }
    );

    function clickOnSuggestion(e: any) {
        ReactGA.event({
            category: "Examples",
            action: props.source + ": User chose suggestion",
            label: e.target.innerText
        })
        onSuggestionClick(e);

    }

    function displaySearchSuggestions(res: any) {
        if (res) {
            if (res["queryStr"] === standardizeTexts(stringHandle).toLowerCase().trim()) {
                let filtered = res["suggestions"].filter((s: string) =>
                    (!excludeSuggestions.includes(s) && excludeResults.indexOf(s) < 0));
                let sorted = filtered.sort((a: string, b: string) => {
                    return a.length - b.length;
                });
                setSuggestions({queryStr: res["queryStr"], suggestions: sorted});
            } else {
                //     set str here?
                getSearchSuggestions();
            }
        }
    }

    function suggestionTextStyle(sText: string, id_part: string) {

        if (suggestions) {
            return Utils.boldStrInText(suggestions.queryStr, sText, id_part);
        }

    }

    useEffect(() => {
        if (stringHandle &&
            standardizeTexts(stringHandle).length >= 2 &&
            ((suggestions &&
                standardizeTexts(stringHandle) !== standardizeTexts(suggestions?.queryStr))) || !suggestions) {
            getSearchSuggestions();
        }

    }, [stringHandle, quietSuggestions]);

    useEffect(() => {
    }, [suggestions]);

    useEffect(() => {
    }, [quietSuggestions])


    return (
        <div>
            {!quietSuggestions && !isLoadingSearchResults && suggestions && suggestions["suggestions"].length > 0 && (
                <div className="suggestionsBox">
                    <div className={(isLocked ? "disabled" : '') + " suggestionsWrapper p-3"}>

                        {suggestions.suggestions.map((s: string, index: number) => (
                            <div className={(isLocked ? "disabled" : "hoverPointer ") + " suggestionRow pt-1 pb-3 "}
                                 onClick={clickOnSuggestion}
                                 key={index}>{suggestionTextStyle(s, 'search' + index)} </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )

}

export default Suggestions;
