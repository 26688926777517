import apiClient from "../common/api-client";
import UiConfig from "../common/ui-config";


const searchQuestions = async (org_id: string, search_string: string, debugMode: boolean) => {

    let body: {};
    let t: {};
    let c: {};

    t = {"textual_input": search_string}
    search_string.length > 0 ? c = {"conceptual_input": search_string} : c = {}

    body = Object.assign({}, t, c);
    let url;
    debugMode ? url = `/questions/search?org_id=${org_id}&include_debug_data=1` : url = `/questions/search?org_id=${org_id}`
    const response = await apiClient.post<any>(url, body);
    return response.data;
}

const getSearchSuggestions = async (org_id: string, search_string: string) => {
    let t: {};

    t = {"textual_input": search_string}
    // param to exclude non-numeric categories
    const response = await apiClient.post<any>(`/questions/search-suggestions?org_id=${org_id}&non_numeric=0&number_of_results=50`, t);
    return response.data;
}

const getQuestionById = async (org_id: string, q_id: string | undefined) => {
    if (q_id === undefined) return Error
    const response = await apiClient.get<any>(`/questions/${q_id}?org_id=${org_id}`);
    return response.data;

}

const getRandomQuestions = async (org_id: string, results_number: number) => {
    const response = await apiClient.get<any>(`/questions/random?org_id=${org_id}&results_number=${results_number}`);
    return response.data;

}


const getQStatisticsPredictionById = async (org_id: string, q_id: string | undefined) => {
    if (q_id === undefined) return Error
    const response = await apiClient.get<any>(`/questions/${q_id}/statistics?org_id=${org_id}`);
    return response.data;
}
const getStatisticsPredictionForText = async (org_id: string, q_text: string | undefined) => {
    if (q_text === undefined) return Error
    let q_id = 'custom';
    const response = await apiClient.get<any>(`/questions/${q_id}/statistics?text=${encodeURIComponent(q_text)}&org_id=${org_id}`);

    return response.data;
}

const getQStudyQuestions = async (org_id: string, q_id: string | undefined) => {
    if (q_id === undefined) return Error
    const response = await apiClient.get<any>(`/questions/${q_id}/studies?org_id=${org_id}`);

    return response.data;

}

const getCorrelations = async (org_id: string, q_id: string | undefined, requestTexts: string[] | undefined) => {
    if (q_id === undefined) return Error
    let body = {
        "base_question_id": q_id,
        "other_questions": requestTexts
    };
    const response = await apiClient.post<any>(`/questions/${q_id}/correlation?org_id=${org_id}`, body);
    let res: any;
    const {correlation_for_question_texts} = response.data;
    res = correlation_for_question_texts;

    return res;
}
const getCorrelationsForTexts = async (org_id: string, custom_text: string | undefined, requestTexts: string[] | undefined) => {
    if (custom_text === undefined) return Error
    let body = {
        "base_question_text": custom_text,
        "other_questions": requestTexts
    };
    const response = await apiClient.post<any>(`/questions/correlation?org_id=${org_id}`, body);
    const {correlation_for_question_texts} = response.data;
    let res: any;
    res = correlation_for_question_texts;

    return res;
}

const getSubscalesForText = async (org_id: string, custom_text: string | undefined, requestTexts: string[] | undefined) => {
    if (custom_text === undefined) return Error
    let body = {
        "base_question_text": custom_text,
        "other_questions": requestTexts,
        "number_subscales_result": UiConfig.numberTopSubscales,
    };
    const response = await apiClient.post<any>(`/questions/top_subscales?org_id=${org_id}`, body);
    return {
        "subscales": response.data.subscales,
        "linear_regression": response.data.regression_data.linear_regression,
        "accuracy_r2": response.data.regression_data.accuracy_r2
    };
}

const getStudyCorrelationsForTexts = async (abort_signal: any, org_id: string, base_text: string | undefined, requestTexts: string[] | undefined) => {
    if (base_text === undefined) return Error
    let body = {
        "base_question_text": base_text,
        "other_question_texts": requestTexts
    };
    const response = await apiClient.post<any>(`/questions/study-correlations?org_id=${org_id}`, body);
    return response.data;
}

const getLinearRegression = async (org_id: string, q_id: string | undefined, requestTexts: string[] | undefined) => {
    if (q_id === undefined) return Error
    let body = {
        "question_texts": requestTexts
    };
    const response = await apiClient.post<any>(`/questions/${q_id}/linear-regression?org_id=${org_id}`, body);
    return {
        "linear_regression": response.data.linear_regression,
        "accuracy_r2": response.data.accuracy_r2
    };
}
const getLinearRegressionForText = async (org_id: string, custom_text: string | undefined, requestTexts: string[] | undefined) => {
    if (custom_text === undefined) return Error
    let body = {
        "base_text": custom_text,
        "comparison_texts": requestTexts
    };
    const response = await apiClient.post<any>(`/questions/linear-regression?org_id=${org_id}`, body);

    return {
        "linear_regression": response.data.linear_regression,
        "accuracy_r2": response.data.accuracy_r2
    };
}

const getMostCorrelated = async (org_id: string, q_id: string | undefined) => {
    if (q_id === undefined) return Error
    const response = await apiClient.get<any>(`/questions/${q_id}/most-correlated?org_id=${org_id}`);
    return response.data;

}
const getMostCorrelatedForText = async (org_id: string, custom_text: string | undefined) => {
    if (custom_text === undefined) return Error
    let body = {"base_text": custom_text}
    const response = await apiClient.post<any>(`/questions/most-correlated?org_id=${org_id}`, body);
    return response.data;
}

const getRelatedArticles = async (org_id: string, question_text: string | undefined) => {
    if (question_text === undefined) return Error
    let body = {"question_text": question_text}
    const response = await apiClient.post<any>(`/questions/related_gscholar?org_id=${org_id}`, body);
    return response.data.related_gscholar_articles;
}

const getNetwork = async (org_id: string, _text: string) => {
    let body = {"base_text": _text}
    console.log("QService reporting: Request to /questions/network");
    const response = await apiClient.post<any>(`/questions/network?org_id=${org_id}`, body);
    return response.data;
}
const getConnections = async (org_id: string, _text: string, connect_stmt: string) => {
    let body = {"statement1": _text, "statement2": connect_stmt}
    console.log("QService reporting: Request to /questions/connections");
    console.log("sending body: ", body);
    const response = await apiClient.post<any>(`/questions/connections?org_id=${org_id}`, body);
    return response.data;
}
const reduceConnections = async (org_id: string, stmts: string[], connections: {}[]) => {
    let body = {"stmts": stmts, "connections": connections}
    console.log("QService reporting: Request to /questions/reduce_connections");
    console.log("sending body: ", body);
    const response = await apiClient.post<any>(`/questions/reduce_connections?org_id=${org_id}`, body);
    return response.data;
}


const QuestionDataService = {
    searchQuestions,
    getSearchSuggestions,
    getQuestionById,
    getQStatisticsPredictionById,
    getStatisticsPredictionForText,
    getQStudyQuestions,
    getCorrelations,
    getCorrelationsForTexts,
    getStudyCorrelationsForTexts,
    getLinearRegression,
    getLinearRegressionForText,
    getMostCorrelated,
    getMostCorrelatedForText,
    getRandomQuestions,
    getNetwork,
    getSubscalesForText,
    getRelatedArticles,
    getConnections, reduceConnections,
}

export default QuestionDataService;
