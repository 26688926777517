import StudyType from "../../types/study.type";
import {Offcanvas} from "react-bootstrap";
import {LoadingGif} from "../UIElements";
import React, {useEffect, useState} from "react";
import {CorrelationDetails} from "../../requestHooks/useStudyDetails";
import Utils from "../../common/utils";

interface StudyInfoProps {
    data?: StudyType[] | null;
    requestedIndex?: number | undefined;
    correlationDetails?: CorrelationDetails | undefined;
    isLoading: boolean;
    show: boolean;
    handleClose: () => void;
}

const StudyInfoComponent: React.FC<StudyInfoProps> = (props) => {
    let {data, requestedIndex, correlationDetails, isLoading, show, handleClose} = {...props}
    const study = (requestedIndex !== undefined && data) ? data[requestedIndex] : null
    const [confInterval, setConfInterval] = useState<any>(null);

    function fishersZ(r: number) {
        return 0.5 * Math.log((1 + r) / (1 - r));
    }

    function inverseFishersZ(z: number) {
        return (Math.exp(2 * z) - 1) / (Math.exp(2 * z) + 1);
    }

    function confidenceInterval95(r: number, n: number) {
        const Z = fishersZ(r);
        const SEZ = 1 / Math.sqrt(n - 3);
        const Zmin = Z - 1.96 * SEZ;
        const Zmax = Z + 1.96 * SEZ;
        const rmin = inverseFishersZ(Zmin);
        const rmax = inverseFishersZ(Zmax);
        return [rmin, rmax];
    }

    useEffect(() => {
    }, [confInterval]);

    useEffect(() => {
        if (correlationDetails) {
            const confInterval = confidenceInterval95(Number(correlationDetails.correlation), Number(correlationDetails.sample_size));
            setConfInterval(confInterval);
        } else {
            setConfInterval(null);
        }
    }, [data, requestedIndex, isLoading, show, handleClose]);

    return (
        <Offcanvas className="studyInfoOffcanvas" show={show} onHide={handleClose} placement="bottom">
            <Offcanvas.Header closeButton className="pb-0">
                {/*<Offcanvas.Title><span className="lead ps-5">Study Details</span></Offcanvas.Title>*/}
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex align-items-center ps-3 pt-0">
                {!isLoading && study && (
                    <div>
                        {correlationDetails && (
                            <div>
                                <div className="corrDetails">
                                    {Utils.formatCorrelation(correlationDetails.correlation)} correlation
                                    {confInterval && !isNaN(confInterval[0]) && !isNaN(confInterval[1]) && (
                                        <span>
                                            ({Utils.formatCorrelation(confInterval[0])} to {Utils.formatCorrelation(confInterval[1])} is the
                                            95<sup>th</sup> percentile confidence interval)
                                        </span>
                                    )} calculated
                                    using {Number(correlationDetails.sample_size).toLocaleString("en-US", {minimumFractionDigits: 0})} data
                                    points.
                                </div>
                            </div>
                        )}
                        <h5 className='sourceDetails'>Source:</h5>
                        <p>
                            <a href={study.url} target='_blank' rel="noopener noreferrer">
                                {study.name} {(study.start_year || study.end_year) && (
                                <span> ({study.start_year === study.end_year ? study.start_year :
                                    <span>{study.start_year}&mdash;{study.end_year}</span>})</span>
                            )}
                            </a>
                        </p>
                        {study.sample_size && (
                            <p>
                                {study.sample_size.toLocaleString("en-US")} participants
                                {study.countries && study.countries.length > 0 && (
                                    <span> from {study.countries.length > 6 ? study.countries.length + " different countries" : study.countries.map((c, index) => (
                                        <span key={index}>{c}</span>
                                    ))}</span>
                                )}
                            </p>
                        )}
                        {(study.maximum_age || study.minimum_age) && (
                            <p>ages {study.minimum_age}&mdash;{study.maximum_age},
                                with {study.percent_man && (
                                    <span>{study.percent_man}% males </span>
                                )}{(study.percent_man && study.percent_woman) && (
                                    <span>and </span>
                                )}{study.percent_woman && (
                                    <span>{study.percent_woman}% females </span>
                                )}
                            </p>
                        )}
                        {(study.notes) && (<p>{study.notes}</p>)}
                    </div>
                )}
                {isLoading && (
                    <LoadingGif width="50px"/>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default StudyInfoComponent;