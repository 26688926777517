import {LoadingSimple} from "../UIElements";
import React, {useEffect, useState} from "react";
import QuestionType from "../../types/question.type";
import useWindowDimensions from "../../common/useWindowDimentions";
import UiConfig from "../../common/ui-config";
import {e} from "mathjs";
import ReactGA from "react-ga4";

type Props = {
    questionsForExamples: QuestionType[],
    isLoadingExamples: boolean,
    lockRequests: boolean,
    onExampleClick: (e: any) => void,
    randomizeExamplesClicked?: () => void,
    source?: string,

}

const ExamplesComponent = (props: Props) => {
    const {questionsForExamples, isLoadingExamples, lockRequests, onExampleClick, randomizeExamplesClicked} = {...props}
    const {height, width} = useWindowDimensions();
    let EXAMPLES_LEN = 350;
    if (width < 1200) {
        EXAMPLES_LEN = 150;
    }
    const [searchExamples, setSearchExamples] = useState<string[]>([]);

    const handleClick = (e: any) => {
        ReactGA.event({category: "Examples", action: props.source + ": User chose example", label: e.target.innerText})

        onExampleClick(e);
    };

    function handleRandomExamples(res: QuestionType[]) {
        if (!isLoadingExamples) {
            if (res && res.length) {
                let texts = res.map((q: QuestionType) => {
                    return q.canonical_text
                })
                // determine number of examples based on total length
                let newArr: string[] = [];
                // randomizeExamplesClicked === undefined ? newArr = ['woman', 'age'] : newArr = []

                texts.reduce((sumLen, t, currentIndex, array) => {
                    if ((sumLen + t.length < EXAMPLES_LEN) && t.length > 0) {
                        newArr.push(t)
                        return sumLen + t.length
                    } else {
                        return sumLen
                    }
                }, 0)

                setSearchExamples(newArr)
            }
        }


    }


    useEffect(() => {
        if (questionsForExamples.length > 0) {
            handleRandomExamples(questionsForExamples);
        } else {
            setSearchExamples(UiConfig.initialRegressionExamples)
        }

    }, [props.questionsForExamples]);

    return (
        <table className="table-borderless examplesDiv mt-3 pb-5">
            <tbody>
            <tr className='pt-2 ps-0'>
                {searchExamples.length > 0 && !isLoadingExamples && (
                    <td className="align-top pe-2 d-flex flex-wrap">
                        {searchExamples.map((e: string, index: number) => (
                            <div key={index}
                                 className={lockRequests ? "exampleSpan locked" : "hoverPointer exampleSpan"}
                                 onClick={handleClick}>{e}</div>
                        ))} {randomizeExamplesClicked !== undefined && (
                        <span className="randomizeExamplesBtn hoverPointer"
                              onClick={randomizeExamplesClicked}>Randomize</span>)}
                    </td>
                )}
                {isLoadingExamples && (
                    <td>
                        <div className="" style={{width: "3rem"}}><LoadingSimple/></div>
                    </td>

                )}

            </tr>

            </tbody>


        </table>
    )
}

export default ExamplesComponent;
