import CytoscapeComponent from 'react-cytoscapejs';
import React, {useEffect, useRef} from "react";
import {LoadingSimple} from "../UIElements";
import setupCy from "./setupCy";
import cytoscape from "cytoscape";
import {layouts} from "./layouts";

setupCy();

type VerboseDiagramProps = {
    elements: any[];
    layoutName: string;
    style: React.CSSProperties;
    showCorrelation: boolean;
    edgeWidthCoefficient: number;
    nodesLimit: number;
    latestNodeId: string;
    reload: boolean;
    loading: boolean;
    onLayoutApplied: () => void;
};

const VerboseDiagram: React.FC<VerboseDiagramProps> = (props) => {
    const {
        elements,
        layoutName,
        style,
        showCorrelation,
        edgeWidthCoefficient,
        nodesLimit,
        latestNodeId,
        reload,
        loading,
        onLayoutApplied,
    } = props;

    const [layout, setLayout] = React.useState<any>({
        name: layoutName,
        padding: 10,
        nodeSpacing: 50,
    });

    const cyRef = useRef<cytoscape.Core | null>(null);

    useEffect(() => {
        if (reload) {
            console.log('Reloading layout');
            onLayoutApplied();
        }
    }, [reload, layoutName, onLayoutApplied]);

    useEffect(() => {
        const cy = cyRef.current;
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }

        if (nodesLimit > 6) {
            console.log(`Nodes limit is above 6: ${nodesLimit}`);
            const latestNode = cy.elements(`node[id="${latestNodeId}"]`);
            const edges = cy.edges(`[source="${latestNodeId}"], [target="${latestNodeId}"]`);
            setTimeout(() => {
                console.log(`Highlighting latest node: ${latestNodeId}`);
                latestNode.style("color", "white");
                edges.style('lineColor', "white");
                setTimeout(() => {
                    latestNode.style("color", "black");
                    edges.each((ele: cytoscape.EdgeSingular) => {
                        const labelValue = parseFloat(ele.data('label'));
                        console.log(`Edge ${ele.id()} label value: ${labelValue}`);
                        if (labelValue < 0) {
                            ele.style('lineColor', 'red');
                        } else if (labelValue > 0) {
                            ele.style('lineColor', 'green');
                        }
                    });
                }, 150);
            }, 600);
        }
    }, [elements, latestNodeId, nodesLimit]);

    useEffect(() => {
        const cy = cyRef.current;
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }

        console.log('Setting fixed positions for Statement1 and Statement2');

        const statement1 = cy.$('node[id="Statement1"]');
        const statement2 = cy.$('node[id="Statement2"]');
        const container = cy.$('node[id="StatementContainer"]');

        statement1.position({x: cy.width() / 2, y: 50});
        statement2.position({x: cy.width() / 2, y: cy.height() - 50});

        cy.batch(() => {
            if (statement1.length > 0) {
                console.log('Locking Statement1 position');
                statement1.lock();
            }
            if (statement2.length > 0) {
                console.log('Locking Statement2 position');
                statement2.lock();
            }
        });

        if (container.length > 0) {
            const containerNodes = container.descendants();
            if (containerNodes.length > 0) {
                container.style('height', cy.height() - 100);
                console.log('Applying breadthfirst layout within StatementContainer');
                const containerLayout = containerNodes.layout({
                    name: 'breadthfirst',
                    spacingFactor: 1.5,
                });
                containerLayout.run();
            }
        }
    }, [elements, layoutName]);

    function getDefaultStylesheet() {
        console.log('Generating default stylesheet');
        return [{selector: "node", style: {label: "data(label)"}}];
    }

    const [stylesheet] = React.useState<any>(getDefaultStylesheet);

    const handleCy = (cy: cytoscape.Core) => {
        console.log('Cytoscape component initialized');
        cyRef.current = cy;
        (cy as any).gridGuide({
            snapToGridOnRelease: false,
            drawGrid: false,
            gridSpacing: 20,
            resize: true,
            parentPadding: true,
            centerToEdgeAlignment: true,
            distributionGuidelines: true,
            parentSpacing: -1,
        });

        cy.edges().forEach(edge => {
            const weight = edge.data('weight');
            edge.style("width", weight * edgeWidthCoefficient);
        });

        cy.on("select", "edge", (event: any) => {
            const selectedEdge = event.target;
            selectedEdge.addClass('selected');
        });

        cy.on('unselect', 'edge', (event) => {
            const unselectedEdge = event.target;
            unselectedEdge.removeClass('selected');
        });
    };

    const cytoscapeStylesheet: any[] = [
        {
            selector: "node",
            style: {
                width: "label",
                "background-color": "#ffffff",
                height: "label",
                "padding-top": "20",
                "padding-bottom": "20",
                "padding-left": "20",
                "padding-right": "20",
                "text-margin-y": -3,
                shape: "round-rectangle",
                "z-index": 10,
            }
        },
        {
            selector: "node[label]",
            style: {
                label: "data(label)",
                "font-size": "22",
                color: "black",
                "text-halign": "center",
                "text-valign": "center",
                "text-wrap": "wrap",
                "text-max-width": 300,
                "text-background-color": "white",
                "text-background-opacity": 1,
                "text-background-shape": "round-rectangle",
                "text-border-color": "white",
                "text-border-width": 1,
                "text-border-opacity": 1
            }
        },
        {
            selector: "edge",
            style: {
                label: showCorrelation ? 'data(label)' : '',
                'curve-style': 'bezier',
                'target-arrow-shape': 'none',
                'text-background-color': 'white',
                'text-background-opacity': 0.9,
                'text-background-padding': '4px',
                width: 1.5,
                "z-index": 5,
            }
        },
        {
            selector: "edge:selected",
            style: {
                label: "data(label)",
                "font-size": "16",
                "text-background-color": "white",
                "text-background-opacity": 1,
                "text-background-padding": "2px",
                "text-margin-y": -4,
                "text-events": "yes",
            }
        }
    ];

    useEffect(() => {
        const cy = cyRef.current;
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }

        console.log('Processing elements');
        elements.forEach((element) => {
            if (!element) return;

            if (element.group === 'nodes' && !cy.elements(`node[id="${element.data.id}"]`).length) {
                cy.add({
                    group: 'nodes',
                    data: {id: element.data.id, label: element.data.label},
                    position: element.position || undefined,
                });
            }

            if (element.group === 'edges' && !cy.elements(`edge[id="${element.data.id}"]`).length) {
                cy.add({
                    group: 'edges',
                    data: {
                        id: element.data.id,
                        source: element.data.source,
                        target: element.data.target,
                        weight: element.data.weight,
                        label: element.data.label,
                    },
                });
            }
        });

        if (layoutName === 'preset') {
            console.log('Applying preset layout');
            const containerNodes = cy.filter('node[parent="StatementContainer"]');
            if (containerNodes.length > 0) {
                const containerLayout = containerNodes.layout({
                    name: 'breadthfirst',
                    padding: 10,
                    nodeSpacing: 50,
                } as any);
                containerLayout.run();
            }
        }
    }, [elements, layoutName]);

    useEffect(() => {
        const cy = cyRef.current;
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }
        console.log('Resetting, fitting, and centering Cytoscape');
        cy.reset();
        cy.fit();
        cy.center();
        setLayout((layout: any) => ({...layout, padding: [50, 0, 0, 0]}));
    }, [elements, nodesLimit, reload]);

    useEffect(() => {
        const cy = cyRef.current;
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }
        console.log(`Running layout: ${layoutName}`);
        const layout = cy.layout(layouts[layoutName]);
        layout.run();
    }, [layoutName, reload]);

    if (loading) {
        console.log('Loading...');
        return <div><LoadingSimple/></div>;
    }

    return (
        <CytoscapeComponent
            boxSelectionEnabled={false}
            cy={handleCy}
            elements={elements}
            layout={layout}
            style={style}
            stylesheet={cytoscapeStylesheet}
        />
    );
};

export default VerboseDiagram;