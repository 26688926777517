import {useQuery, useQueryClient} from 'react-query';
import {useCallback, useEffect} from 'react';
import QuestionDataService from '../services/question.service';

export function useCorrelation(
    org_id: string,
    id: string,
    predictCorrelationText: any,
    baseQuestion: any,
    custom_text: any,
    handleCorrelationResult: Function,
    handleError: Function
) {
    const queryClient = useQueryClient();

    const fetchCorrelation = useCallback(async () => {
        if (predictCorrelationText.length > 0) {
            let request_texts = [predictCorrelationText];
            if (custom_text) {
                return await QuestionDataService.getCorrelationsForTexts(org_id, custom_text, request_texts);
            }
            if (!custom_text && baseQuestion) {
                return await QuestionDataService.getCorrelations(org_id, id, request_texts);
            }
        }
    }, [org_id, id, predictCorrelationText, baseQuestion, custom_text]);

    const {isFetching: isLoadingCorrelation, data: correlationData, error, refetch: requestCorrelation} = useQuery(
        ["query-request-correlations", org_id, id, predictCorrelationText, custom_text],
        fetchCorrelation,
        {
            enabled: false, // will be triggered manually
            retry: 2,
            cacheTime: 1000 * 60 * 5, // Adjust cache time to 5 minutes
            onSuccess: (res) => {
                handleCorrelationResult(res[0]);
            },
            onError: (err) => {
                handleError(err);
            }
        }
    );

    useEffect(() => {
        if (error) {
            handleError(error);
        }
    }, [error, handleError]);

    useEffect(() => {
        queryClient.invalidateQueries(["query-request-correlations", org_id, id, predictCorrelationText, custom_text]);
    }, [predictCorrelationText, custom_text, org_id, id, queryClient]);

    return {isLoadingCorrelation, requestCorrelation};
}