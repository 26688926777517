// MetaTags.tsx
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

interface MetaTagsProps {
    location: string;
    description?: string;
    shareUrl?: string;
}


const UI_STRINGS = require('../../common/ui-strings.json')


const MetaTags: React.FC<MetaTagsProps> = ({location, description, shareUrl}) => {

    const [docTitle, setDocTitle] = useState<string>(UI_STRINGS.metaTags.titles.default);

    useEffect(() => {
        setDocTitle(UI_STRINGS.metaTags.titles[location])
    }, [location]);

    useEffect(() => {
    }, [])

    return (
        <Helmet>
            <title>{docTitle}</title>
            <meta property="og:title"
                  content={docTitle}/>
            {description && (
                <>
                    <meta property="og:description" content={description}/>
                </>
            )}
            {shareUrl && (
                <>
                    <meta property="og:url" content={shareUrl}/>
                </>
            )}
        </Helmet>
    );
};

export default MetaTags;