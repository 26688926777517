import {Button} from "react-bootstrap";
import React, {useEffect} from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";

type Props = {
    authenticatedUser: any,
    onSignUpClick: () => void,
    onSignInClick: () => void,
    onSignOutClick: () => void,
    authModalShow: string | null,
}

const AuthPanel = (props: Props) => {
    const {authenticatedUser, onSignUpClick, onSignInClick, onSignOutClick,} = {...props}

    useEffect(() => {
    }, [props.authModalShow, authenticatedUser]);

    useEffect(() => {
    }, []);

    return (
        <div className="auth-panel">
            <a href="/about" className="btn btn-link auth-link logInButton">About</a>
            {authenticatedUser ? (
                <button className="btn btn-link logInButton" onClick={onSignOutClick}>Sign&nbsp;out</button>
            ) : (
                <>
                    <button className="btn btn-link logInButton" onClick={onSignInClick}>Log in</button>
                    <Button size="lg" className="btn signUpButton" onClick={onSignUpClick}>
                        Sign&nbsp;up<span className="d-none d-lg-inline">&nbsp;for free</span>
                    </Button>
                </>
            )}
        </div>
    )
}
export default AuthPanel;