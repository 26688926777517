// Called this function for transform data for network diagram
import NetworkDiagramData from "../../types/networkDiagram.type";
import ConnectionsDiagramData from "../../types/connectionsDiagram.type";

export const getNetworkDiagramElements = (data: NetworkDiagramData, nodesLimit: number, seenEdges: Set<string>) => {
    const newElements: any[] = [];
    const limit = Math.min(data.length, nodesLimit);

    for (let i = 0; i < limit; i++) {
        const item = data[i];
        // Create nodes
        const nodes = [
            {data: {id: item.base_question_text, label: item.base_question_text}},
        ];
        newElements.push(...nodes);

        // create edges for this specific node
        const edges = item.other_questions.map((other_text: any, cIndex: number) => {
            if (newElements.some(el => el.data?.id === other_text)) { // check if target node already exists
                const edge = [
                    item.base_question_text,
                    other_text,
                    item.correlation_for_question_texts[cIndex].correlation
                ].join('|'); // create a sorted pair key
                if (seenEdges.has(edge)) {
                    return null; // edge already existed
                }
                seenEdges.add(edge); // add new unique edge
                return {
                    data: {
                        source: item.base_question_text,
                        target: other_text,
                        weight: Math.abs(item.correlation_for_question_texts[cIndex].correlation),
                        label: item.correlation_for_question_texts[cIndex].correlation.toFixed(2),
                    },
                    style: {
                        width: Math.abs(item.correlation_for_question_texts[cIndex].correlation) * 20,
                        lineColor: item.correlation_for_question_texts[cIndex].correlation > 0 ? 'green' : 'red'
                    }
                }
            }
        }).filter(Boolean); // discard null edges (non-uniques)
        newElements.push(...edges);
    }
    return newElements;

}


export const getConnectionsDiagramElements = (fullData: ConnectionsDiagramData, regressionThreshold: number, containerWidth: number, containerHeight: number) => {
    const newElements: any[] = [];

    const data = fullData.connections;

    // Add the primary statement nodes
    newElements.push({
        data: {
            id: 'Statement1',
            label: fullData.statements[0]
        },
        position: {
            x: containerWidth / 2 - 200,
            y: 0
        }
    });
    newElements.push({
        data: {
            id: 'Statement2',
            label: fullData.statements[1]
        },
        position: {
            x: containerWidth / 2 - 200,
            y: containerHeight - Math.ceil(containerHeight / 10)
        }
    });

    // Add a container node for the inner statements
    newElements.push({
        data: {
            id: 'StatementContainer',
            label: '',
        },
        position: {
            x: 50,
            y: containerHeight / 2
        }
    });

    // Process each statement in the data
    data.forEach(statement => {
        // Adds nodes to Statement1 parent and creates actual hierarchical structure
        newElements.push({
            data: {
                id: statement.text,
                label: statement.text,
                parent: 'StatementContainer', // This will be the parent container
            }
        });

        // Adds visibility edges only for visual clarity
        if (statement.linear_regression[0] && Math.abs(statement.linear_regression[0]) > regressionThreshold) {
            newElements.push({
                data: {
                    source: 'Statement1',
                    target: statement.text,
                    label: statement.linear_regression[0].toFixed(2),
                    weight: Math.abs(statement.linear_regression[0]),
                },
                style: {
                    width: Math.abs(statement.linear_regression[0]) * 20,
                    lineColor: statement.linear_regression[0] > 0 ? 'green' : 'red'
                }
            });
        }

        if (statement.linear_regression[1] && Math.abs(statement.linear_regression[1]) > regressionThreshold) {
            newElements.push({
                data: {
                    source: 'Statement2',
                    target: statement.text,
                    label: statement.linear_regression[1].toFixed(2),
                    weight: Math.abs(statement.linear_regression[1]),
                },
                style: {
                    width: Math.abs(statement.linear_regression[1]) * 20,
                    lineColor: statement.linear_regression[1] > 0 ? 'green' : 'red'
                }
            });
        }
    });


    // Process the Statement1 to Statement2 edge with the correlation data
    newElements.push({
        data: {
            source: 'Statement1',
            target: 'Statement2',
            label: fullData.correlation.toFixed(2),
            weight: Math.abs(fullData.correlation),
        },
        style: {
            width: Math.abs(fullData.correlation) * 20,
            lineColor: fullData.correlation > 0 ? 'green' : 'red'
        }
    });

    return newElements;
}