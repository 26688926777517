import {useQuery} from 'react-query';
import QuestionDataService from '../services/question.service';


export function useLinearRegression(org_id: string, id: string, predictRegressionTexts: any, baseQuestion: any, custom_text: any, handleRegressionResult: Function, handleError: Function) {

    const {
        isFetching: isLoadingRegression,
        refetch: requestRegression
    } = useQuery<any>(
        "query-request-regression",
        async () => {
            if (predictRegressionTexts.length > 0) {
                if (baseQuestion && !custom_text) {
                    return await QuestionDataService.getLinearRegression(org_id, id, predictRegressionTexts);
                }
                if (custom_text) {
                    return await QuestionDataService.getLinearRegressionForText(org_id, custom_text, predictRegressionTexts);
                }

            }
        },
        {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleRegressionResult(res),
            onError: (err: any) => handleError(err),
        }
    )

    return {isLoadingRegression, requestRegression}
}
