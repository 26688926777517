import React, {useState} from 'react';
import {Button, Offcanvas} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import ReactGA from "react-ga4";

type ShareProps = {
    tab: string,
}

const UI_STRINGS = require('../../common/ui-strings.json')

const HelpButtonComponent: React.FC<ShareProps> = (props) => {
    const [show, setShow] = useState<boolean>(false);
    const {tab} = props;

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        ReactGA.event({category: '? Button', action: 'Clicked Help button'});
        setShow(true);
    };

    const mainText = UI_STRINGS.descriptions[tab] || "";
    const tabTitle = UI_STRINGS.tabNames[tab] || "No Title";
    const tabPic = UI_STRINGS.picturesPath[tab] || "";

    return (
        <>
            <Button variant="secondary" onClick={handleShow} className={"helpBtn"}><i className="bi bi-question-lg"></i></Button>
            <Offcanvas placement="end" show={show} onHide={handleClose} className="custom-offcanvas-width">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>

                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h5 className="ps-3 ms-3">{tabTitle}</h5>
                    <div className="d-flex flex-column align-content-center p-3 m-3 mt-0">
                        {mainText.split('\n').map((paragraph: string, index: number) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default HelpButtonComponent;