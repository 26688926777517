import React, {useState, useRef} from 'react';
import {Button, Tooltip, Overlay} from 'react-bootstrap';
import ReactGA from "react-ga4";

interface CopyToClipboardButtonProps {
    text: string,
}

const CopyToClipboardButton = ({text}: CopyToClipboardButtonProps) => {
    const [copied, setCopied] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        ReactGA.event({category: 'Share', action: 'Clicked Copy button on a result row'})


        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
            setShow(true);
            setTimeout(() => {
                setCopied(false);
                setShow(false);
            }, 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {copied ? 'Copied!' : ''}
        </Tooltip>
    );

    return (
        <>
            <Button ref={target} onClick={handleClick} variant="light" style={{
                padding: 0,
                margin: 0,
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                borderRadius: '5px',
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-copy" viewBox="0 0 16 16">
                    <path
                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                </svg>
            </Button>
            <Overlay target={target.current} show={show} placement="top">
                {renderTooltip}
            </Overlay>
        </>
    );
};

export default CopyToClipboardButton;