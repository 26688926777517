import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import {BrowserRouter} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import {Authenticator} from '@aws-amplify/ui-react';
import App from './App';

import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: true,
            staleTime: 5 * 60 * 1000,
        },
    },
});


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <Authenticator.Provider>
                <App/>
            </Authenticator.Provider>
        </QueryClientProvider>
    </BrowserRouter>
);
