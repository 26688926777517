import {Authenticator, components, Heading, useAuthenticator, useTheme, View} from "@aws-amplify/ui-react";
import {Hub} from 'aws-amplify/utils';
import {Modal} from "react-bootstrap";
import {I18n} from 'aws-amplify/utils';
import {useEffect, useState} from "react";
import {
    accessSearchDetailsPrompt,
    defaultHeader,
    termsSignUpFooter
} from "../UIElements";
import ReactGA from "react-ga4";

const UI_STRINGS = require('../../common/ui-strings.json')
I18n.setLanguage('en');

I18n.putVocabularies({
    en: {
        'Sign In': 'Log In',
    },
});
type Props = {
    onSignInCustom: () => void,
    show: any,
    onHide: () => void,

}

const AuthModal = (props: Props) => {
    const {onSignInCustom, show, onHide} = {...props}
    const listener = (data: any) => {
        switch (data.payload.event) {
            case 'signedIn':
                console.log('user have been signedIn successfully.');

                onSignInCustom()
                break;
            case 'signedOut':
                console.log('user have been signedOut successfully.');
                break;
            case 'tokenRefresh':
                console.log('auth tokens have been refreshed.');
                break;
            case 'tokenRefresh_failure':
                console.log('failure while refreshing auth tokens.');
                break;
            case 'signInWithRedirect':
                console.log('signInWithRedirect API has successfully been resolved.');
                break;
            case 'signInWithRedirect_failure':
                console.log('failure while trying to resolve signInWithRedirect API.');
                break;
            case 'customOAuthState':
                console.log('custom state returned from CognitoHosted UI');
                break;

        }
    };
    const hubListenerCancelToken = Hub.listen('auth', listener);


    const formFields = {
        signUp: {
            email: {
                order: 1
            },
            password: {
                order: 2
            },
            confirm_password: {
                order: 3
            }
        },
    }

    const components = () => {
        if (props.show) {

            let reason: string;
            props.show.slice(-5) === 'Click' ? reason = 'click' : reason = 'auto'
            let action = props.show.slice(0, 6);
            let footer = termsSignUpFooter;
            // action === 'signUp' ? footer = termsSignUpFooter : footer = {};
            let header;
            reason === 'auto' ? header = accessSearchDetailsPrompt : header = defaultHeader;
            const result = Object.assign({}, header, footer);
            return result
        } else return {}

    }
    const initialState = () => {
        if (props.show) {
            if (props.show.substring(0, 5) == 'SignUp' || props.show.substring(0, 5) == 'SignIn') return props.show.substring(0, 5)
            else return 'SignUp'
        }
    }


    useEffect(() => {
        return () => {
            hubListenerCancelToken();
        };
    }, [])

    return (
        <Modal {...props} centered backdrop="static" className="authModal" size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"p-0"}>
                <Authenticator key={props.show} initialState={show && show.startsWith('signUp') ? 'signUp' : 'signIn'}
                               signUpAttributes={[
                                   'email',
                               ]} formFields={formFields} components={components()}>
                </Authenticator>

            </Modal.Body>
            <Modal.Footer>
                <div className="pb-5 pt-3"></div>
            </Modal.Footer>

        </Modal>


    )

}
export default AuthModal;
