import React from 'react';
import {useQuery} from 'react-query';
import QuestionDataService from "../../services/question.service";
import {LoadingSimple} from "../UIElements";

// define your Article type here
type Article = {
    id: number;
    title: string;
    publication_year?: number,
    authors?: string[],
    citations?: number,
    url?: string,
    abstract?: string
};

type RelatedArticlesProps = {
    question_text: string;
};

const RelatedArticles: React.FC<RelatedArticlesProps> = ({question_text}) => {
    const {data: articles, isLoading, error} = useQuery<Article[], Error>(
        ['relatedArticles', question_text],
        () => QuestionDataService.getRelatedArticles('main', question_text)
    );

    if (isLoading) {
        return <LoadingSimple/>;
    }

    if (error) {
        return <div>An error has occurred: {error.message}</div>;
    }

    if (articles && articles.length > 0) {
        return (<div className='mt-5 pt-3'><span className='lead fs-4 p-2'>Potentially Related Papers</span>
            <ol className="pt-2"> {articles.slice(0, 6).map((article: Article) => {
                const authors = article.authors?.length ? `${article.authors?.map((author: string) => author.split(' ').join('\u00A0')).join(', ')}` : '';
                const publication_year: string = article.publication_year?.toString() ? article.publication_year.toString() : '';
                return (<a key={article.id} href={article.url} target='_blank' rel='noopener noreferrer'
                           className='relatedArticle'>
                    <li className='p-2 pb-1'><span
                        className="text-decoration-underline">{article.title}</span> ({publication_year})&nbsp;&mdash;
                        <span
                            className="fst-italic"> {authors}</span></li>
                </a>);
            })} </ol>
        </div>);
    }
    return null;
};

export default RelatedArticles;