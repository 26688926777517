import Utils from "../../common/utils";
import React, {useEffect} from "react";
import UiConfig from "../../common/ui-config";

interface Correlation {
    text: string;
    correlation: number;
    is_surprising: boolean;
    what_is_surprising: string;
    actualCorrelations: any[];
}

interface ShareTextProps {
    stmt: string;
    shareUrl: string;
    correlations: Correlation[];
    regressionResult: any[];
    modelR2?: any;
    copiedText: string;
    mainText: string; // Adding `mainText` prop
}

const ShareTextComponent: React.FC<ShareTextProps> = ({
                                                          stmt,
                                                          mainText, // Destructure `mainText` prop
                                                          regressionResult,
                                                          copiedText
                                                      }) => {
    useEffect(() => {
    }, [copiedText, regressionResult]);

    useEffect(() => {
    }, [stmt]);

    return (

        <div>
            {mainText.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
            ))}
        </div>

    );
};

export default ShareTextComponent;