import React from "react";

const Privacy = () => {


    return (
        <div className='documents'>
            <h1>Privacy Policy</h1>
            <p>"The Company" refers to Spark Wave, LLC, and all its affiliated products and websites that link to this
                privacy policy.</p>
            <p>The Company (“we”, “our”, or “us”) provides this Privacy Policy to inform you of our policies and
                procedures regarding the collection, use and disclosure of personal information we receive via our
                Service, as defined in the Terms of Service available at <a
                    href="/terms">https://personalitymap.io/terms</a></p>
            <p>This Privacy Policy may be updated from time to time. Your use of the Service following any such update
                will signify and constitute your assent to and acceptance of such revised Privacy Policy. Unless
                otherwise defined in this Privacy Policy, capitalized terms used herein have the same meanings as in our
                Terms of Service. </p><p>This Privacy Policy does not apply to the products or services of any third
            parties, even if their products or services are linked to, or embedded within, our Service. For example, we
            may provide social media features as part of our Service that enable you to share information with your
            social networks. Your use of these features may result in the collection or sharing of information about
            you, depending on the feature. We recommend that you review the privacy statements of the other parties with
            whom you interact before submitting any information to them. </p>
            <p>Privacy Policy Effective Date: Oct 13, 2023</p>
            <h2>1. Information Collection and Use</h2>

            <p>For the purposes of this Privacy Policy, “Personal Information” means information that can be used to
                uniquely identify you, such as your name and e-mail address.</p>

            <h3>Personal Information We Collect</h3>

            <p>When you interact with us, we may ask you to supply us with Personal Information. For example, when you
                create an account with us, participate in a program, or otherwise use our Service, we may ask you to
                provide information such as your name, address, personal thoughts on a topic, and/or e-mail address.</p>

            <p>If you connect with the Service via a social network platform (“SNP”) such as, without limitation,
                Facebook, the SNP will provide us with information, including Personal Information, about you, and we
                will treat that information in accordance with this Privacy Policy. The information we receive from an
                SNP may depend on the privacy settings you have with the SNP, so please check the SNP’s privacy and data
                practices before causing your SNP to connect with our Service.</p>

            <h3>Our Use of Your Personal Information</h3>

            <p>We mainly use your Personal Information, either alone or in combination with other information, so that
                we can provide, enhance, improve and personalize our Service and related content, products, services,
                and marketing efforts.</p>

            <p>The following are examples of a variety of ways that we may use your Personal Information. We may use
                your Personal Information:</p>

            <ul>
                <li>to provide you with the best user experience possible;</li>
                <li>to respond to your questions, and carry out the transactions you have requested;</li>
                <li>to communicate with you regarding Service updates, offers, alerts, promotions, and events and/or
                    about your Content and/or payments;
                </li>
                <li>to help us develop, customize, deliver, support and improve our Service, products, content, and
                    advertising;
                </li>
                <li>to help authenticate any account you create with us;</li>
                <li>to administer promotions and contests;</li>
                <li>to protect the rights or property of The Company or our users, and prevent fraud and other
                    prohibited or illegal activities;
                </li>
                <li>to protect the security of our products, services, and business;</li>
                <li>for research purposes</li>
                <li>for our internal purposes (such as auditing, data analysis, and research to improve our products,
                    services, and communications); and
                </li>
                <li>otherwise, as disclosed to you at the point of collection.</li>
            </ul>

            <p>If you decide at any time that you no longer wish to receive communications from us, please follow any
                unsubscribe instructions that may be provided in our communications to you, or send an e-mail request to
                us at the following e-mail address: <a href="mailto:info@sparkwave.tech">info@sparkwave.tech</a>. Please
                note that we may use your Personal Information to contact you with important notifications, such as
                notices with respect to safety, payments, or changes to our terms, conditions, and policies; you may not
                opt out of these notifications.</p>
            <h3>Public Forums & Content-Sharing Features</h3>
            <p>We may offer video features, message boards, commenting features, or similar public forums where you and
                other users of our Service can publicly post Content or otherwise communicate. For example, the Service
                may include features and functionalities that allow users to post, embed or otherwise share user
                generated programs. The protections described in this Privacy Policy do not apply when you provide
                information (including personally identifiable information about any person) in connection with your use
                of the public forums and information-sharing features we make available. Any information you share in a
                public forum, including without limitation all information in programs you provide for display on the
                Service, is public information and may be seen or collected by other persons, including third parties
                that do not adhere to the standards set forth in this Privacy Policy. We are not responsible for events
                arising from the distribution of any information you choose to publicly post or share through our
                Service.</p>
            <h3>Collection and Use of Non-Identifying Information</h3>

            <p>We may collect, use, transfer, and disclose information in a form that does not personally identify you
                (“Non-Identifying Information”) for any purpose. Non-Identifying Information can include certain
                personally identifiable information that has been de-identified; that is, information that has been
                rendered anonymous. We and/or our third party service providers obtain Non-Identifying Information about
                you from information that you provide us, either separately or together with your Personal Information,
                and from your clicking and other behaviors when using the Service. We and/or our third party service
                providers also automatically collect certain Non-Identifying Information from you when you access our
                Service or, in some cases, when you link your account on the Service with an SNP.</p>

            <p>Certain Non-Identifying Information about you would be considered a part of your Personal Information if
                it were combined with other identifiers in a way that enables you to be identified. However, the same
                pieces of information are considered Non-Identifying Information when they are taken alone or combined
                only with other Non-Identifying information. We may combine your Personal Information with
                Non-Identifying Information and aggregate it with information collected from other users or sources to
                attempt to provide you with a better experience, to improve the quality and value of our products and
                services, to analyze and understand how our products and services are used, and for research purposes.
                We may also use the combined information without aggregating it to serve you specifically.</p>
            <h3>Non-Identifying Information We Automatically Collect</h3>
            <p>When you access our Service, we and our third-party service providers may use a variety of technologies
                and methods, now and hereafter devised, that automatically record certain usage information. This
                information may include your Internet Protocol address (and other unique identifiers for the particular
                device you use to access the Internet), browser type, your web or application request, your interaction
                with our Service, the webpage or other feature you were using before you came to our Service, pages of
                our Websites that you visit, information you search for via our Service, access times and dates, and
                other similar information. We use this information for a variety of purposes, including analyzing and
                enhancing our products and services, and to help personalize our Service for you. We do not treat this
                information as Personal Information (except to the extent required by local law), although if we combine
                it with the Personal Information you provide, we will treat the combined information as Personal
                Information. We and our third-party service providers may automatically collect Non-Identifying
                Information using various methods, including, without limitation, cookies.</p>

            <p>A cookie is a small data file transferred to your computer (or other device) when it is used to access
                our Service. Cookies may be used for many purposes, including to enable certain features of our Service,
                to better understand how you interact with our Service and to monitor aggregate usage by visitors and
                online traffic routing. You may be able to instruct your browser, by changing its options, to stop
                accepting cookies or to prompt you before accepting a cookie from the online services you visit. If you
                do not accept cookies, however, you may not be able to use all portions of our online services or all
                functionality of our online services.</p>
            <h2>2. Information Sharing and Disclosure</h2>
            <p><span style={{color: 'red'}}>Except as described in this Privacy Policy, we will not share your Personal Information with other
                companies or individuals outside of The Company unless we have received your consent.</span> Non-Identifying
                Information may be provided to third parties for marketing, advertising, research or other uses.</p>
            <h3>Our Service Providers and Other Third Parties</h3>
            <p>We may engage third party companies and individuals to perform services on our behalf (e.g., without
                limitation, payment processing, software maintenance services, e-mail notifications, database
                management, web analytics and other services). These third parties may have access to your Personal
                Information. If they do, this access is provided so that they may perform these tasks on our behalf and
                they are not authorized by us to otherwise use or disclose your Personal Information, except to the
                extent required by law.</p>
            <h3>Compliance with Laws, Legal Process and Authorities</h3>

            <p>We cooperate with government and law enforcement officials and private parties to enforce and comply with
                the law. We will disclose any information about you to government or law enforcement officials or
                private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and
                legal process (including but not limited to subpoenas), to protect the property and rights of us or a
                third party, to protect the safety of the public or any person, or to prevent or stop activity we may
                consider to be, or to pose a risk of being, illegal, unethical or legally actionable.</p>

            <h3>Data Transfer</h3>
            <p>Your Personal Information may be transferred to, and used by, entities located outside of your state,
                province, country or other governmental jurisdiction where the privacy laws may not be as protective as
                those in your jurisdiction. Such export is a requirement of providing your information to The Company,
                and you hereby authorize and consent to such export.</p>
            <h3>Business Transfers</h3>
            <p>Circumstances may arise where, whether for strategic or other business reasons, we may sell, transfer or
                otherwise disclose some or all of our assets, including your Personal Information, in connection with a
                merger, acquisition, reorganization or sale of assets or in the event of bankruptcy.</p>
            <h2>3. General</h2>

            <h3>Changing or Deleting Your Information</h3>
            <p>The Company may make available the means to correct or modify certain Personal Information via our
                websites, but you may not have the means to modify some Personal Information after it is entered or
                submitted. Please note we may retain server/backup copies of all such data, and that we may decline to
                process change or deletion requests that we deem to be unreasonable, or require disproportionate
                technical effort, or jeopardize the privacy of others.</p>

            <h3>Security</h3>
            <p>We employ security measures to protect your Personal Information from unauthorized access, alteration,
                disclosure, or destruction. These security measures include administrative and technical precautions to
                safeguard your Personal Information against loss, theft, and misuse. Please be advised, however, that
                while we strive to protect your Personal Information and privacy, we cannot guarantee or warrant the
                security of any information you disclose or transmit to us online and are not responsible for the theft,
                destruction or inadvertent disclosure of your personally identifiable information.</p>

            <h3>Our Policy Toward Children</h3>
            <p>We do not knowingly collect personally identifiable information from children under 13 via our Service,
                and do not target our Service to children under 13. We encourage parents and guardians to take an active
                role in their children’s online activities and interests.</p>

            <h3>Application</h3>
            <p>Our Privacy Policy applies to all of the services offered by The Company and its affiliates, including
                services offered on other sites, but excludes services that have separate privacy policies that do not
                incorporate this Privacy Policy.</p>
            <p>Our Privacy Policy does not apply to services offered by other companies or individuals, including sites
                that may include The Company, or other sites linked from our services. Our Privacy Policy does not cover
                the information practices of other companies and organizations who advertise our services, and who may
                use cookies and other technologies to serve and offer relevant ads.</p>

            <h3>Contacting Us</h3>
            <p>If you have any questions about this Privacy Policy, please feel free to contact us via e-mail to <a
                href="mailto:info@sparkwave.tech">info@sparkwave.tech</a>
                .</p>
        </div>
    );
}
export default Privacy