import React, {useEffect, useState} from "react";
import UiConfig from "../../common/ui-config";
import {Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import FactorsDataType from "../../types/factorsDataType";
import {re} from "mathjs";

interface IChartData {
    name: string;
    value: number;
}

type Props = {
    data: FactorsDataType;
    factorsSet: any;
};

const FactorsChart = (props: Props) => {
    const {data} = {...props}
    const [factors, setFactors] = useState<any>(props.factorsSet)
    const subscales = data["subscales"]
    const [chartData, setChartData] = useState<IChartData[]>([])
    const [maxAbsValue, setMaxAbsValue] = useState<number>(0)

    const values: number[] = data["linear_regression"]

    function calcChartData() {
        if (subscales) {
            const chartData: IChartData[] = Object.keys(factors)
                .map((f: string) => {
                    let i = subscales?.indexOf(factors[f])
                    if (i !== undefined && i !== -1) {
                        return {
                            name: String(f),
                            value: isNaN(values[i]) ? 0 : Number(values[i].toFixed(2)),
                        }
                    } else {
                        return null; // Return null when 'i' is not present or -1
                    }
                })
                .filter(item => item !== null) as IChartData[]; // Filter out null values

            // ());
            setChartData(chartData);
        }
        if (subscales === undefined) {
            const chartData: IChartData[] = Object.keys(factors).map((label, index) => ({
                name: String(label),
                value: isNaN(values[index]) ? 0 : Number(values[index].toFixed(2)),
            }));
            setChartData(chartData);
        }

    }

    function getFactors() {
        let filterValues = subscales;  //values you want to keep
        let yourObject: { [k: string]: string } = UiConfig.factors.subscales;  //declare yourObject as an indexable type
        let filteredObject: { [key: string]: any } = {};

        if (filterValues) {
            let filteredKeys = Object.keys(yourObject)
                .filter(key => (filterValues?.includes(yourObject[key])) ?? false);

            filteredObject = filteredKeys.reduce((obj, key) => {
                obj[key] = yourObject[key];
                return obj;
            }, {} as { [key: string]: any });
        }
        setFactors(filteredObject);
    }


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (chartData) {
            const maxAbsValue = Math.max(...chartData.map((obj: { value: number; }) => Math.abs(obj.value)), 0.5);
            setMaxAbsValue(maxAbsValue);
        }

    }, [chartData]);

    useEffect(() => {
        if (!subscales) {
            setFactors(props.factorsSet);
        }
        if (subscales) {
            getFactors();
        }
    }, [subscales]);


    useEffect(() => {
        calcChartData();
    }, [factors]);


    useEffect(() => {
        const updateViewportWidth = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', updateViewportWidth);

        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, []);

    return (
        <ResponsiveContainer width="100%" height={350} className="">
            {chartData && (
                <BarChart
                    data={chartData}
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                >

                    <ReferenceLine y={0} stroke="var(--theme-teal-dark)" strokeDasharray="3 3"/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" interval={0} tick={{fontSize: 16, fill: '#000000'}}
                           angle={viewportWidth < 993 ? -30 : 0}
                           textAnchor={viewportWidth < 993 ? "end" : "middle"}
                           height={viewportWidth < 993 ? 160 : undefined}/>
                    <YAxis domain={[-maxAbsValue, maxAbsValue]}/>
                    {/*<Tooltip/>*/}
                    <Bar dataKey="value">
                        {
                            chartData.map((entry, index) => (
                                <Cell key={index} fill={entry.value >= 0 ? '#006f6c' : '#CC2F06'}/>
                            ))
                        }
                    </Bar>
                </BarChart>)}
        </ResponsiveContainer>
    );
}
export default FactorsChart;
