import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import FeedbackForm from "./feedback.component";

const UI_STRINGS = require('../../common/ui-strings.json')

const ContactUsButton = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Button variant="outline-dark" onClick={handleShow} size={'md'} className='contactBtn'>
                Feedback&nbsp;<span
                className="d-none d-lg-inline">/ Contact Us</span>
            </Button>

            <Modal show={show} onHide={handleClose} centered size={"lg"} dialogClassName="modal-30w">
                <Modal.Header closeButton>
                    <Modal.Title className="ps-3">{UI_STRINGS.labels.feedback_button}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-3 pt-0">{UI_STRINGS.labels.feedback_prompt}</div>
                    <div className="p-3">
                        <FeedbackForm/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ContactUsButton;