import axios from "axios";

let emailUrl = process.env.REACT_APP_MAIL_SERVER;
let xApiKey = process.env.REACT_APP_SEND_MAIL;

const emailClient = axios.create({
    baseURL: emailUrl,
    headers: {
        "Content-type": "application/json",
        "x-api-key": xApiKey,

    },
});
export default emailClient;
