import cytoscape from 'cytoscape';
// @ts-ignore
import klay from 'cytoscape-klay';
// @ts-ignore
import fcose from 'cytoscape-fcose';
// @ts-ignore
import cola from 'cytoscape-cola';
// @ts-ignore
import elk from 'cytoscape-elk';
// @ts-ignore
import dagre from 'cytoscape-dagre';
// @ts-ignore
import coseBilkent from 'cytoscape-cose-bilkent';
// @ts-ignore
import cise from 'cytoscape-cise';
// @ts-ignore
import gridGuide from 'cytoscape-grid-guide';

cytoscape.use(klay);
cytoscape.use(fcose);
cytoscape.use(cola);
cytoscape.use(elk);
cytoscape.use(dagre);
cytoscape.use(coseBilkent);
cytoscape.use(cise);
cytoscape.use(gridGuide);

export default function setupCy() {
    // Any additional Cytoscape setup can be placed here.
}