import React from 'react';
import ContactUsButtonComponent from "../components/authAndNav/contact-us-button.component";

const aboutPerformance = require('./../assets/about.png');

const About = () => {

    return (
        <div className="pt-3 pb-5">
            <h1 class="pt-5 pb-3">About PersonalityMap</h1>
            <div>
                <p>PersonalityMap is a platform to help you understand the relationship between "everything and
                    everything" with regard
                    to human psychology. We provide access to over 1 million correlations spanning personality,
                    demographics, behaviors,
                    and beliefs. In addition, we provide a powerful and one-of-a-kind interface for exploring these
                    relationships,
                    allowing you to investigate questions of interest from a variety of angles. PersonalityMap can be
                    used to generate
                    new hypotheses, as well as to refine and confirm (or disconfirm) existing hypotheses. You can use
                    PersonalityMap's
                    tools to investigate an extremely diverse array of questions, such as:</p>
                <ul>
                    <li>Are conservatives happier than progressives?</li>
                    <li>Is anxiety linked to irritability?</li>
                    <li>Do women tend to be more spiritual than men?</li>
                    <li>Do extraverts see themselves as being more charismatic than other people do?</li>
                    <li>What traits are most predictive of school grade point average?</li>
                    <li>What are the predictors of being a "nerd"?</li>
                    <li>How do people who believe in the paranormal differ from those that don't?</li>
                    <li>What are the factors related to religious belief?</li>
                    <li>What are the predictors of addiction?</li>
                    <li>How do those who are heavy smokers differ from those who don't?</li>
                    <li>How do more conformist people differ from those who break social rules?</li>
                    <li>What are the predictors of people enjoying being the center of attention?</li>
                    <li>Do depressed people worry about the future more than other people do?</li>
                    <li>What is the relationship between procrastination and the Big Five personality trait of
                        conscientiousness?
                    </li>
                </ul>
                <p>PersonalityMap is powered by an advanced, novel machine-learning algorithm of our own design. While
                    its predictions
                    are not always accurate, in a study testing its accuracy, it outperformed 100% of non-experts and
                    99% of academic
                    psychologists in predicting correlations:</p>
                <p><img src={aboutPerformance} alt="PersonalityMap Performance"
                        className="img-fluid p-3"/></p>
                <p>To learn more, see "<a target="_blank" href="https://arxiv.org/abs/2406.08170">Can AI Understand
                    Human Personality?
                    -- Comparing Human Experts and AI Systems at Predicting Personality Correlations</a>."</p>
                <p>PersonalityMap is a creation of <a target="_blank" href="https://www.sparkwave.tech/">Spark Wave</a>.
                </p>
                <p>If you have questions about PersonalityMap or any feedback or suggestions for how we could improve
                    the system, we'd
                    love to hear from you. Contact us by clicking here:</p>
            </div>

            <ContactUsButtonComponent/>
        </div>
    );
};

export default About;