import React from 'react';

const Terms = () => {

    return (
        <div>
            <h1 id="h.tw1dvbaw3j63">General Terms of Use</h1>
            <p>
                BY ACCESSING OR USING THE SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT,
                WHETHER OR
                NOT YOU ARE A REGISTERED USER OF OUR SERVICE. IF ANY OF THESE TERMS ARE UNACCEPTABLE TO YOU OR IN THE
                EVENT THAT ANY
                FUTURE CHANGES ARE UNACCEPTABLE TO YOU, DO NOT USE THE SERVICE. YOUR CONTINUED USE OF THE SERVICE NOW,
                OR FOLLOWING
                THE POSTING OF ANY CHANGES IN THIS AGREEMENT, WILL INDICATE ACCEPTANCE AND AGREEMENT BY YOU OF SUCH
                CHANGES.
            </p>
            <p>
                YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ENTER INTO THIS AGREEMENT AND TO BE BOUND BY ITS
                TERMS. IF YOU
                ARE ACTING ON BEHALF OF A COMPANY OR ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH
                ENTITY.
            </p>
            <p>
                The PersonalityMap website (our “service”) gives you free and/or paid access to broad psychological
                resources,
                including powerful servers running complex algorithms at your request, large volumes of data, and an
                innovative
                computation and delivery mechanism that provides you with results and visualizations.
            </p>
            <p>
                We work to improve and expand PersonalityMap, by creating new algorithms or refining existing
                algorithms.
            </p>
            <p>
                To protect our servers, algorithms, and data collections from abuse or exploitation by a small number of
                users, to
                make our operations sustainable, and because some of the data we make available comes from third parties
                that impose
                conditions on its distribution, we need you to agree to, and abide by, the Terms of Use defined below.
            </p>
            <p>
                These terms are a contract you have to agree to before using the PersonalityMap service. If you are
                under age 18,
                you may use PersonalityMap only if you have permission from a parent, legal guardian, or teacher. By
                using the
                service, you are telling us either that you are over the age of 18 and legally able to form contracts or
                that an
                adult with authority to act on your behalf has agreed to these terms and to be responsible for ensuring
                your
                compliance with them in your use of the service and any results you obtain from it. If you don’t want to
                be bound by
                these terms, do not use the service or its results.
            </p>
            <p>
                Our intention is to make PersonalityMap available to everyone indefinitely, and to make PersonalityMap
                self-sustaining. To achieve this, we reserve the right to cut off use by those who abuse the service or
                to limit
                access in any other way for any reason. We are counting on the users of PersonalityMap to embrace our
                goals and to
                support them by adhering to these terms.
            </p>
            <h2 id="h.hehsx4bi3n78">Methods of Access</h2>
            <p>
                It is not permitted to use Artificial Intelligence (A.I.) or web scrapers to extract data from the
                service, or to
                train A.I. models on the data from this website. This service is intended only to be used by humans
                through the web
                based interface. The data represented on this website belongs to Spark Wave and is proprietary. It
                cannot be
                reproduced, stored (except for private use) or displayed by others without express written
                permission. </p>
            <p>
                The PersonalityMap service may be used only by a human being using a conventional web browser to
                manually enter
                queries one at a time. PersonalityMap also offers an API - contact us to learn more about it. Because
                PersonalityMap
                is doing computation, not just lookup, each query may require significant CPU and/or GPU time. Any
                attempt to use a
                robot, script, or organized group of humans to repeatedly access PersonalityMap could place an
                unacceptable load on
                the system, and is strictly forbidden.
            </p>
            <p>
                If our monitoring systems detect an attempt to access the service in a forbidden way, to execute
                systematic patterns
                of queries, to index the website, or to do anything else that we feel jeopardizes the integrity of our
                system or
                access to it by other users, we may terminate or suspend access to the service for specific users or IP
                ranges. In
                cases where such accessing is for illegal purposes, we may take legal action.
            </p>
            <h2 id="h.f1elltb2ouml">Ways You May Use Our Free Service and Its Results</h2>
            <p>
                The free PersonalityMap service is available for ad hoc, personal, non-commercial research use only. You
                may use it
                to get information for your own use for any purpose, including occasional purposes related to your job,
                as long as
                you aren’t specifically being paid to use PersonalityMap. PersonalityMap may be used for academic use,
                but in such
                cases any publications resulting from its use must give full credit to PersonalityMap for anything it
                was used for,
                and provide the following link to the PersonalityMap website: https://www.personalitymap.io/
            </p>
            <p>
                Systematic professional or commercial use of the website, or use for which you are being specifically
                paid, requires
                a commercial license. If you are interested in a version of PersonalityMap that allows such use, please
                contact us.
            </p>
            <p>
                It is permitted to use and post individual, incidental results or small groups of results from
                PersonalityMap on
                non-commercial websites and blogs, provided those results are properly credited to PersonalityMap (see
                Attribution
                and Licensing below). It is not permitted to extract multiple pieces of data and reassemble them into
                anything with
                the characteristics of a data service, database, or source of large and systematic collections of data.
                You are not
                allowed to use PersonalityMap to create something that is likely or intended to be reused as a data
                source for
                further processing or that in some other way serves as a replacement or alternative to using
                PersonalityMap itself.
                This applies whether what you create is in electronic or print form.
            </p>
            <p>PersonalityMap may not be used to train AI systems.</p>
            <p>
                It is not permitted to use PersonalityMap indirectly through any user interface that has created a large
                number of
                deep links to PersonalityMap, or that automatically constructs links based on input that you give to
                that interface
                rather than through PersonalityMap. You may not in effect use PersonalityMap through any alternate user
                interface.
            </p>
            <p>
                Finally, it’s also not permitted to use frames, browser technologies, or any user interface to embed or
                surround the
                PersonalityMap website with your own material without permission from PersonalityMap. If you are
                interested in using
                PersonalityMap in an embedded way, please contact us.
            </p>
            <p>
                In all instances, it is your responsibility to evaluate the accuracy, timeliness, completeness, or
                usefulness of
                results and services provided by PersonalityMap. We do not endorse or warrant, and are not responsible
                for, the
                accuracy, timeliness, completeness, or reliability of any opinion, advice, or statement made on the
                Service by
                anyone other than our authorized employees or spokespersons while acting in their official capacities.
            </p>
            <p>
                PersonalityMap may contain links to other websites or services maintained by third parties. We do not
                operate or
                control, in any respect, or necessarily endorse the content found on third-party websites or services.
                You assume
                sole responsibility for your use of third-party links, websites, products and services.
            </p>
            <h2 id="h.jjvu026aovm">Attribution and Licensing</h2>
            <p>
                As PersonalityMap is an authoritative source of information, maintaining the integrity of its data and
                the
                computations we do with that data is vital to the success of our project. We generate information
                ourselves, and we
                also gather data from multiple external sources. Where we have used external sources of data, the
                assemblages of
                data you get from PersonalityMap do not come directly from any one external source. In many cases, the
                data you are
                shown never existed before in exactly that way outside of PersonalityMap, so its provenance traces back
                both to
                underlying data sources and to the algorithms and knowledge built into the PersonalityMap computational
                system. As
                such, the results you get from PersonalityMap are correctly attributed to PersonalityMap itself.
            </p>
            <p>
                If you make results from PersonalityMap available to anyone else or incorporate those results into your
                own
                documents or presentations, you must include attribution indicating that the results and/or the
                presentation of the
                results came from PersonalityMap, and you must provide a link to PersonalityMap. Whenever possible, such
                attribution
                should take the form to the specific query on PersonalityMap that generated the results you used, or
                when that's not
                feasible, to the PersonalityMap homepage: https://www.personalitymap.io. Some PersonalityMap results may
                include
                copyright statements or attributions linking the results to us or to third-party data providers, and you
                may not
                remove or obscure those attributions or copyright statements.
            </p>
            <p>
                Failure to properly attribute results from PersonalityMap is not only a violation of these terms but may
                also
                constitute academic plagiarism or a violation of copyright law. Attribution is something we expect you
                to give us in
                exchange for us having provided you with a high-quality service.
            </p>
            <p>
                The specific images and results, such as plots, typeset formulas, and tables, calculations, and figures
                as well as
                the general page layouts, are all copyrighted by PersonalityMap at the time PersonalityMap generates
                them. A great
                deal of scholarship and innovation is included in the results generated and displayed by PersonalityMap,
                including
                the presentations, collections, and juxtapositions of data, and the choices involved in formulating and
                composing
                mathematical results; these are also protected by copyright.
            </p>
            <p>
                You may use any results, including copyrighted results, from PersonalityMap for personal use and in
                academic or
                non-commercial publications, provided you comply with these terms.
            </p>
            <p>
                If you want to use results returned by PersonalityMap in a commercial or for-profit publication, we will
                usually be
                happy to grant you a license to do so. To request a commercial-use license, contact us and provide the
                input for
                which you want to use the corresponding output along with information concerning the nature of your
                proposed use.
                Your request will be reviewed and answered as quickly as is practical.
            </p>
            <h2 id="h.p4dde5bes7uu">Linking to PersonalityMap</h2>
            <p>
                We are happy for you to link to the PersonalityMap website, including deep links to specific results.
                However, if
                you are constructing a very large number of deep links, or any deep links that are created automatically
                in response
                to user input given on your site, you must take into account the restrictions enumerated in the other
                sections of
                this document. If you construct a website that induces others to use our service contrary to those
                terms, you are
                inducing them to violate our Terms of Use, and can be liable for those violations.
            </p>

            <h2 className="c1 c2" id="h.d25x0p1qcc54">
                <span className="c6">Input Ownership and Privacy</span>
            </h2>
            <p className="c1">
                <span className="c0">By putting input into PersonalityMap in the form of a query, you are not relinquishing any of your ownership or rights to the content. By providing it to us in query form, you agree that we can store it in log files and use it to generate the results given back to you. We may also use stored inputs for research purposes, or to evaluate the performance of the website or study usage patterns, for example examining inputs that seem to create problems for the system, or testing new versions of the system against logs of previous inputs.</span>
            </p>
            <p className="c1">
                <span className="c0">We will not attempt to associate individual PersonalityMap inputs with individual de-anonymized human users and will not release individual lists of inputs, or any personally identifiable information, to any third party, except in response to lawful court orders. For more details, see our privacy policy.</span>
            </p>

            <h2 className="c1 c2" id="h.tv3legonzr36">
                <span className="c6">Data Mining and Reverse Engineering</span>
            </h2>
            <p className="c1">
                <span className="c0">The PersonalityMap service uses large collections of data aggregated from many sources, and sophisticated computational and algorithms. You may not use multiple queries or specially constructed queries in an attempt to extract large datasets, to reverse engineer the algorithms used by PersonalityMap, or to probe for vulnerabilities.</span>
            </p>
            <p className="c1">
                <span className="c0">Spidering, data-mining, scraping, or probing PersonalityMap, or otherwise attempting to abuse the service, is not only a violation of these terms but may also constitute a violation of federal and state laws concerning unauthorized access to computer systems.</span>
            </p>
            <p className="c1">
                <span className="c0">You are forbidden to violate or attempt to violate the security of the website. If you find a way to crash the website or otherwise get it to misbehave, or you identify a vulnerability, flaw, or bug, please use the feedback form on any PersonalityMap page, or contact us, to report the problem. As there will no doubt always be ways to cause trouble for the site, we ask for your cooperation in ensuring that it remains available for use by everyone.</span>
            </p>
            <p className="c1">
                <span className="c0">The material from the PersonalityMap service is intended to be used by a human being in the form in which it is delivered. You are not allowed to systematically extract data from the results returned by PersonalityMap and build them into a new raw data table, source, language model, or AI system, except on an ad hoc basis for personal use.</span>
            </p>

            <h2 className="c1 c2" id="h.rivmeucvg30j">
                <span className="c6">Professional Disclaimers and Disclaimer of Warranty</span>
            </h2>
            <p className="c1">
                <span className="c0">PersonalityMap provides a rich and wide range of computations and data in many subject areas. It is not, however, a certified or definitive source of information that can be relied on for legal, financial, medical, life-safety, or any other critical purposes.</span>
            </p>
            <p className="c1">
                <span className="c0">Common sense, and these Terms of Use, require that you independently verify the accuracy, completeness, and relevance of any information you get from PersonalityMap before relying on it for any purpose in which things of value are at stake. We specifically disclaim all forms of liability for all results from PersonalityMap.</span>
            </p>
            <p className="c1">
                <span className="c0">In some fields, definitive information can legally be provided only by certified and paid professionals. Examples include, among others, medical, legal, or financial advice, measures used in commerce, engineering calculations, and surveying or navigation data. These fields make use of many kinds of information and calculations, some of which are also available in PersonalityMap. The availability of this kind of information in PersonalityMap does not imply that PersonalityMap purports to provide any official or certified information or professional services of any kind.</span>
            </p>
            <p className="c1">
                <span className="c0">PersonalityMap is not a substitute for information or guidance provided by any licensed professional in any field, and if you need advice of this nature, you should consult a professional who is licensed or knowledgeable in the appropriate area.</span>
            </p>
            <p className="c1">
                <span className="c0">Although PersonalityMap does not deliver in its results any user-supplied raw code executable in a browser or other execution environment, we cannot guarantee that a suitably constructed input could not cause the website to generate results pages that cause unintended or harmful operations on the client system. PersonalityMap specifically disclaims any responsibility for harm to your system caused by use of the website.</span>
            </p>
            <p className="c1">
                <span className="c0">The information provided by PersonalityMap is provided &ldquo;as is&rdquo;, and for information purposes only. This means that PersonalityMap does not offer any warranty whatsoever with regard to this service, its content, or any content or services accessible from the website. You agree that you are using the service at your own risk.</span>
            </p>
            <p className="c1">
                <span className="c0">In no event shall PersonalityMap or its directors, officers, shareholders, subsidiaries, affiliates, agents, or licensors be liable for any damages arising out of or related to the use, inability to use, performance, or nonperformance of this service. Some states or other jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential damages, so the above limitations and exclusions may not apply to you.</span>
            </p>
            <p className="c1">
                <span className="c0">You agree to indemnify and hold PersonalityMap and its directors, officers, shareholders, subsidiaries, affiliates, agents, and licensors harmless from and against all losses, expenses, damages, and costs, including attorneys’ fees, resulting from your violation of these Terms of Use, including, without limitation, any use of this website other than as expressly authorized by these Terms of Use.</span>
            </p>
            <p className="c1">
                <span className="c0">We cannot always foresee or anticipate technical or other difficulties which may result in failure to obtain data or loss of data, personalization settings, or other interruptions. We cannot assume responsibility for the timeliness, accuracy, deletion, non-delivery or failure to store any user data, communications or settings.</span>
            </p>
            <p className="c1">
                <span className="c0">By connecting PersonalityMap with a third-party service (e.g., Facebook), you give us permission to access and use information, content and/or material you have supplied to that service as permitted by that service, and to store your log-in credentials for that service.</span>
            </p>
            <p className="c1">
                <span className="c0">You acknowledge and agree that your use of the Service, including, without limitation, the storage of any data, files, information and/or other materials on a server owned or under our control or in any way connected to the Service, shall be at your sole risk and responsibility and we shall have no obligation to back-up such data, files, information and/or other materials. We expressly reserve the right to limit storage capacity and to remove and/or delete any data, files, and/or other information stored or used in connection with the Service for any reason if we deem, in our sole discretion, such data to be in violation of this Agreement and/or any rule or policy of ours and/or any local, state, or federal law or regulation.</span>
            </p>
            <p className="c1">
                <span className="c0">You understand that some information found on PersonalityMap may be offensive, upsetting, politically incorrect, or misleading, and by using the service you agree that you are okay with viewing this information.</span>
            </p>

            <h2 className="c1 c2" id="h.ijpe3562vx2h">
                <span className="c6">Limitations of Use</span>
            </h2>
            <p className="c1">
                <span className="c0">You represent, warrant, and agree that you will not:</span>
            </p>
            <ol className="c8 lst-kix_ayrw6xog6wp7-0 start" start="1">
                <li className="c1 c3 li-bullet-0"><span className="c0">use the Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this Agreement and/or any and all applicable local, state, national and international laws and regulations and treaties;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">use the Service for the distribution, housing, processing, propagation, storage, or other handling of, any Content or other material prohibited by this Agreement, or any material that may create a risk of any loss or damage to any person or property, or any other material that we deem, in our sole discretion, to be objectionable whether or not such material is unlawful;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Permit or otherwise enable unauthorized users to access and/or use the Service;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Use the Service to export software or data in violation of applicable U.S. laws or regulations;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Sell, copy, duplicate, rent, lease, loan, distribute, transfer, or sublicense the Service, or otherwise permit any third party to use or have access to the Service for any purpose (except as expressly permitted by us in writing) or decompile, reverse engineer, disassemble, modify, create a derivative work of, display in human readable form, attempt to discover any source code, or otherwise use any software that enables or comprises any part of the Service;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Remove any copyright, trademark, patent or other proprietary notices from the Service or any Content on the Service;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Distribute, publish, exhibit, or otherwise use the Service, in any manner and for any purpose not expressly permitted under this Agreement;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Frame or utilize framing techniques to enclose the Service, or any portion thereof, except when explicitly and purposely permitted by the Service;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Exploit the Service or collect any data incorporated in the Service in any automated manner through the use of bots, metaspiders, crawlers or any other automated means;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Register as a user of the Service by providing false, inaccurate, or misleading information;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Post hyperlinks to commercial services or websites;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Impersonate any person or entity, including, without limitation, an employee of ours, or falsely state or otherwise misrepresent your affiliation with a person or entity;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Collect personal data about other users of the Service for commercial or any other purposes;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Post or otherwise make available irrelevant Content (as defined below), repeatedly post or make available the same or similar Content or otherwise impose an unreasonable or disproportionately large load on our infrastructure;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Attempt to gain unauthorized access to our computer systems (including any non-public areas of the Service) or engage in any activity that disrupts, diminishes the quality of, probes for vulnerability, interferes with the performance of, or impairs the functionality of, the Service (or the servers and networks which are connected to the Service);</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Make available Content (as defined below) that in our opinion constitutes or contains &quot;affiliate marketing,&quot; &quot;link referral codes,&quot; &quot;junk mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot; &quot;pyramid schemes,&quot; or unsolicited commercial advertisement, except to the extent such Content is provided to you by us or one of our affiliates for a purpose specifically authorized by us in writing;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Create or attempt to create more than three user accounts;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Access or use the Service if you have been previously removed from the Service by us;</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Use the Service to encourage any conduct that (1) violates any applicable law or regulation, or any term or condition of this Agreement, or (2) could create a risk of harm, loss or damage of any kind; or</span>
                </li>
                <li className="c1 c3 li-bullet-0"><span className="c0">Attempt to access or search the Service or Content in order to scrape any Content or information from the Service, or otherwise use, upload Content to, or create new links, posts or referrals in the Service through the use of any computerized or mechanized process, engine, software, tool, agent, device or mechanism other than the software provided by us or other generally available third-party web browsers.</span>
                </li>
            </ol>
            <p className="c1">
                <span className="c0">To use certain features and functionalities of the Service, you must create an account with us, and provide certain personal information about yourself to us. You agree and understand that you are responsible for maintaining the confidentiality of passwords associated with any account you use to access the Service. Accordingly, you agree that you will be solely responsible to us for all activities that occur under your account. If you become aware of any unauthorized use of your password or of your account, you agree to notify us immediately.</span>
            </p>

            <h2 className="c1 c2" id="h.8t8efy5evjxj">
                <span className="c6">Intellectual property</span>
            </h2>
            <p className="c1">
                <span className="c0">You acknowledge and agree that we (or our licensors) own all legal right, title and interest in and to PersonalityMap and its results, including, without limitation, any intellectual property rights which subsist in the Service (whether those rights happen to be registered or not, and wherever in the world those rights may exist) including, without limitation, all rights with respect to copyrights, patents, trademarks, service marks, moral rights, trade names, domain names, technology, mask works, know-how, design rights, trade dress, trade secrets, inventions, ideas, processes, formulas, source code and object code, data, and similar rights including, without limitation, the information in any application, registration, or renewal thereof that may be protected under the intellectual property laws, regulations, or rules of any country. Without limiting the foregoing, the &ldquo;look and feel&rdquo; of the Service, and the compilation, assembly, and arrangement of the materials of the Service and any and all copyrightable material, and all intellectual property rights to the same, are owned or controlled by us, our licensors, or both.</span>
            </p>
            <p className="c1">
                <span className="c0">You agree that you shall not remove, obscure, or alter any legal notices or proprietary rights notices (including copyright and trademark notices) which may be affixed to or contained within the Service.</span>
            </p>
            <p className="c1">
                <span className="c0">These terms do not grant you the right to use any branding or logos used in our Services. You agree that in using the Service, you will not use any trademark, service mark, trade name, logo of any company or organization in a way that is likely or intended to cause confusion about the owner or authorized user of such marks, names or logos.</span>
            </p>

            <h2 className="c1 c2" id="h.udri9p9x6klv">
                <span className="c6">Reporting Incorrect or Infringing Material</span>
            </h2>
            <p className="c1">
                <span className="c0">If you find something in PersonalityMap that you feel is incorrect, we encourage you to report it; we take great pride in making the information we provide as accurate as possible. Click here to report errors, or use the feedback form provided on each PersonalityMap page.</span>
            </p>
            <p className="c1">
                <span className="c0">If you find something in PersonalityMap that you believe to be a violation of a copyright or other right that you personally hold, we want to know so we can correct the problem. Please use the feedback or contact us feature to tell us where the material is, and why you think it is infringing.</span>
            </p>
            <h2 id="h.bto7go4xy48k">Interpretation of Terms and Changes</h2>
            <p>Terms of Use are to be interpreted under and governed by the laws of the State of New York. If any
                provision of the
                Terms of Use is held to be invalid or unenforceable, such provision shall be deemed superseded by a
                valid
                enforceable provision that most closely matches the intent of the original provision, and the remaining
                provisions
                shall continue in full force and effect. In any event, such an invalid or unenforceable provision shall
                have no
                effect on the validity or enforceability of the remaining provisions.</p>
            <p>Any dispute with us, or our parents, subsidiaries, affiliates, officers, directors, employees, agents, or
                affiliates,
                arising under or in relation to this Agreement, shall be resolved exclusively through the federal or
                state courts
                located in New York County, New York (and courts with appellate jurisdiction therefrom), except with
                respect to
                imminent harm requiring temporary or preliminary injunctive relief in which case we may seek such relief
                in any
                court with jurisdiction over the parties. You understand that, in return for agreement to this
                provision, we are
                able to offer the Service at the terms designated, and that your assent to this provision is an
                indispensable
                consideration to this Agreement.</p>
            <p>You also acknowledge and understand that, with respect to any dispute with us, our officers, directors,
                employees,
                agents or affiliates, arising out of or relating to your use of the Service or this Agreement:</p>
            <ul>
                <li>YOU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY; and</li>
                <li>YOU ARE GIVING UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY
                    OTHER
                    REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT
                    INVOLVING ANY
                    SUCH DISPUTE.
                </li>
            </ul>
            <p>We may choose to correct or amend these Terms of Use at any time. You will be responsible for reviewing
                the latest
                version of this Agreement posted on the PersonalityMap website from time to time to check for amendments
                that may
                apply to you. We retain the right at our sole discretion to deny or suspend access to the Service to
                anyone, at any
                time and for any reason, without liability.</p>
            <p>You agree that we may communicate with you electronically. Such electronic communications may consist of
                e-mail,
                notices posted on the Service, mobile notification, and other communications. You agree that all
                agreements,
                notices, disclosures, and other communications we send to you electronically will satisfy any
                requirement that such
                communication be in writing and, to the extent intended, such communication will be an enforceable and
                binding term
                or amendment to this Agreement.</p>

            <h2 id="h.m6e8gtjat5e7">Data Retention</h2>
            <p>Spark Wave takes privacy and data protection seriously and is committed to safeguarding data you share
                with us. To
                learn more, please see our <a href="/privacy-policy">privacy policy</a>.</p>
        </div>
    );
};

export default Terms;