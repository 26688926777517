// This function does two things;
// (i) Finds and removes any period ('.') that is not followed by an extra period at the start or a period that is not preceeded by a period at the end of the text.
// (ii) Finds and removes any period (not bounded by any other period) followed by any closing quote (in the list of closing quote, all_closing_quotes)
function removeTrailingPeriod(text: string) {
    let atext: string = text.trim();
    // pattern matches only when string text starts with a '.' (without any succeeding '.') or ends with a '.' (without any preceeding '.')
    // let pattern_1 = /^[^.]*\.[^.]*$/
    // pattern matches for period ('.') that is not bounded by periods.
    // let pattern_2 = /(?<!\.)\.(?!\.)/

    let btext = atext;
    if (atext.match(/\.$/) || atext.match(/^\./)) {
        btext = atext.replace(/\.$/, '').replace(/^\./, '');
    }

    // let ctext = btext
    // let all_closing_quotes = ["'", '"', "”", "’"]
    // for (let quote in all_closing_quotes) {
    //     let pattern = new RegExp("(?<!\\.)\\.(?!\\.)" + quote);
    //     ctext = btext.replace(pattern, quote);
    // }
    return btext;
}

//This function removes any matching open-and-close quotes at the beginning and end of a text. The quotes have to match each other to be removed.
function quotesCharacterHandler(text: string) {
    let matching_quotes = {"'": "'", '"': '"', "“": "”", "”": "“", "‘": "’"}
    for (const [k, v] of Object.entries(matching_quotes)) {
        if (text.startsWith(k)) {
            let subText = text.slice(1)
            let last_index = subText.length - 1
            let end_quote_position = subText.indexOf(v)
            if (end_quote_position === last_index) {
                text = text.slice(1, text.length - 1)
            }
        }
    }
    return text
}


// This function changes all different quotes to a single quote, (')
function changeAllQuotesToSingleQuote(text: string) {
    text = text.trim()
    text = text.replace(/"|“|”|’|‘/g, "'")
    return text
}

// This function applies 'remove_trailing_period', 'quotes_character_handler', and 'change_all_quotes_to_single_quotes' functions to clean up texts.
function standardizeTexts(text: string) {
    let sampleText = text
    let standardizeText = true
    let standardText = text
    while (standardizeText) {
        // this doesn't work on iphone
        standardText = removeTrailingPeriod(sampleText)

        standardText = quotesCharacterHandler(standardText)
        if (sampleText === standardText) {
            standardText = changeAllQuotesToSingleQuote(standardText)
            standardizeText = false
        } else {
            standardizeText = true
            sampleText = standardText
        }
    }

    return standardText
    // return text
}

export default standardizeTexts;
