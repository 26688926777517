import React, {useEffect, useReducer, useState} from "react";
import {Route, Routes, useLocation, useNavigate,} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style.scss";

import QuestionSearch from "./components/question-search.component";
import Question from "./components/question.component";
import {withRouter} from "./common/with-router";
import {promptForQPage} from "./components/UIElements";
import {Amplify} from 'aws-amplify';
import {
    getCurrentUser,
    fetchAuthSession,
    signUp,
    signIn,
    type SignInInput,
    signOut,
    fetchUserAttributes
} from 'aws-amplify/auth';
import {Authenticator, useAuthenticator, useTheme} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import AuthModal from "./components/authAndNav/auth.component";
import AuthPanel from "./components/authAndNav/authPanel";
import ReactGA from 'react-ga4';
import AppSettings from "./common/app-settings";
import ContactUsButton from "./components/authAndNav/contact-us-button.component";
import NotFoundPage from "./static/not-found-page";
import Terms from "./static/terms";
import Privacy from "./static/privacy";
import About from "./static/about";
import {Helmet} from "react-helmet";
import QuestionType from "./types/question.type";
import MetaTags from "./components/authAndNav/MetaTags";

Amplify.configure(config);

const logo = require('./assets/logo.png');

ReactGA.initialize(AppSettings.GAId);
ReactGA.gtag('event', 'page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    send_to: AppSettings.GAId
});


const App = () => {
    const [authenticatedUser, setAuthenticatedUser] = useState<any>(null);
    const [locationKey, setLocationKey] = useState<string>('');
    // 0 - no modal, 1 - login, 2 - signup
    const [authModalShow, setAuthModalShow] = React.useState<string | null>(null);
    let navigate = useNavigate();
    const location = useLocation();
    const secondLevelPath = location.pathname.split('/')[1];
    const goHome = () => {
        let path = `/`;
        navigate(path);
    }
    const currentAuthenticatedUser = async () => {
        try {
            const result = await getCurrentUser();
            setAuthenticatedUser(result);
        } catch (error) {
            // Handle error
        }
    }

    const handleSignOut = async () => {
        localStorage.removeItem('userEmail');
        setAuthenticatedUser(null);
        try {
            await signOut().then(() => {
                ReactGA.send({
                        hitType: 'event',
                        eventCategory: 'Auth',
                        eventAction: 'User signed out'
                    }
                )
            });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    const {route, toSignIn, toSignUp} = useAuthenticator(context => [context.route]);


    function requireAuth() {
        setAuthModalShow("signUp");
        ReactGA.send({
                hitType: 'event',
                eventCategory: 'Auth',
                eventAction: 'App requested authorization from user',
                nonInteraction: true
            }
        )

    }

    function onSignUpClick() {
        ReactGA.send({
                hitType: 'event',
                eventCategory: 'Auth',
                eventAction: 'User clicked sign up button'
            }
        );

        setAuthModalShow("signUpClick");
    }

    function onSignInClick() {
        ReactGA.send({
                hitType: 'event',
                eventCategory: 'Auth',
                eventAction: 'User clicked login button'
            }
        );
        setAuthModalShow("signInClick")
    }

    function onSignInCustom() {
        setAuthModalShow(null);
        currentAuthenticatedUser();

        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Auth',
            eventAction: 'User logged in'
        });
    }


    useEffect(() => {
        if (authenticatedUser) {
            const userId = authenticatedUser.userId
            ReactGA.set({user_id: userId});
        } else ReactGA.set({user_id: null});
    }, [authenticatedUser]);


    useEffect(() => {
        if (authModalShow === null) currentAuthenticatedUser();

        else if (authModalShow.startsWith('signUp')) {
            toSignUp();
        } else if (authModalShow.startsWith('signIn')) {
            toSignIn();
        }
    }, [authModalShow]);

    const titleMustChange = (tabKey: string) => {
        setLocationKey(tabKey);
    }
    useEffect(() => {
    }, [locationKey])

    useEffect(() => {
        let myTitle = '';
        let page = window.location.pathname.split('/')[1];
        let matchesTextAsQuestion = location.pathname.match(/^\/questions\/ut(?:\?text=(.*))?$/);
        let matchesQuestion = location.pathname.match(/^\/questions\/(?:([^\/]*))?$/);
        let matchesSearchPage = location.search.match(/^\?search=(.*?)(&|$)/);

        if (matchesTextAsQuestion) {
            if (location.state && location.state.custom_text) {
                myTitle = 'TextAsQuestion: ' + location.state.custom_text;
            } else if (matchesTextAsQuestion[1]) {
                myTitle = "TextAsQuestion Direct URL: " + matchesTextAsQuestion[1];
            }
        } else if (matchesQuestion) {
            if (location.state && location.state.originalSearch && location.state.originalSearch.originalSearchString) {
                myTitle = 'Question: ' + location.state.originalSearch.originalSearchString;
            } else if (matchesQuestion[1]) {
                myTitle = "Question Direct URL: " + matchesQuestion[1];
            }
        } else if (matchesSearchPage) {
            myTitle = 'Search Page: Results for ' + matchesSearchPage[1];
        } else {
            myTitle = "Home: Blank Search Page";
        }

        ReactGA.send({hitType: 'pageview', page: page, title: myTitle});
    }, [location]);

    useEffect(() => {
        if (authenticatedUser === null) currentAuthenticatedUser();
        return () => {
        }
    }, []);
    useEffect(() => {
        if (authenticatedUser !== null && authenticatedUser.signInDetails) {
            localStorage.setItem('userEmail', authenticatedUser.signInDetails.loginId);

        }
    }, [authenticatedUser])


    useEffect(() => {
        const debugHostnames = ['localhost', '127.0.0.1', '0.0.0.0', 'dev.personalitymap'];

        ReactGA.set({debug: debugHostnames.some(hostname => window.location.hostname.includes(hostname))});
    }, []);
    useEffect(() => {
    }, [location]);

    useEffect(() => {

    }, []);

    return (
        <div className="wrapper 5 d-flex flex-column position-relative">
            <MetaTags location={locationKey ? locationKey : secondLevelPath}/>
            <div className="main_top p-3 hoverPointer d-flex justify-content-between">
                <div className="d-flex justify-content-start align-items-start align-items-lg-center">
                    <img src={logo} alt="" style={{
                        maxHeight: 'fit-content',
                        maxWidth: '70px',
                        height: 'auto',
                        width: 'auto'
                    }}
                         className={window.innerWidth > 998 ? 'img-fluid img-fluid-large' : 'img-fluid'}
                         onClick={goHome}/>
                    <div onClick={goHome} style={{
                        lineHeight: '1.1',
                        paddingRight: '5rem',
                        paddingLeft: '0.5rem',
                        color: 'hsl(190, 100%, 20%)',
                        maxWidth: '40vw',
                        width: '35rem',
                        // fontStyle: 'italic',
                    }}
                         className='lead d-none d-lg-flex'>
                        Explore 1 million human correlations spanning personality, demographics, behaviors, and
                        beliefs
                    </div>
                </div>

                <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                    <AuthPanel authenticatedUser={authenticatedUser} onSignUpClick={onSignUpClick}
                               onSignInClick={onSignInClick} onSignOutClick={handleSignOut}
                               authModalShow={authModalShow}/>
                    <ContactUsButton/>
                </div>

            </div>
            {(
                <AuthModal show={authModalShow} onSignInCustom={onSignInCustom} onHide={() => setAuthModalShow(null)}
                />
            )}


            <div className="container mt-3 flex-grow-1">

                <Routes>
                    <Route path="/" element={<QuestionSearch originalSearch={null} authenticatedUser={authenticatedUser}
                                                             requireAuth={requireAuth}
                                                             searchResultsChanged={titleMustChange}/>}/>
                    <Route path="/questions/:id"
                           element={route === 'authenticated' ? <Question questionTabChanged={titleMustChange}/> :
                               <Authenticator initialState={"signUp"} components={promptForQPage}/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/privacy-policy" element={<Privacy/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </div>
        </div>
    );
}

export default withRouter(App);
